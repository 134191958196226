import client from "lib/api/client"
import { Reservation } from '../../interfaces/index';

// 患者向け予約取得
export const getReservationListByUser = (user_id: string) => {
  return client.get(`reservations/by_user/${user_id}`)
}

// 予約（1件）取得
export const getReservation = (id: string) => {
  return client.get(`reservations/${id}`)
}

// 施設情報を含めた予約詳細取得
export const getReservationWithFacility = (id: string, params: any = null) => {
  return client.post(`reservations/show_with_facility/${id}`, params)
}

//予約ステータス更新
export const updateReservationStatus = (id: number, params: any) => {
  return client.put(`reservations/update_status/${id}`, params)
}

// 医療機関予約（1件）取得
export const showClinicReservation = (id: string, params: any = null) => {
  return client.post(`reservation_clinic/show_by_clinic/${id}`, params)
}

// 医療機関予約（全件）取得
export const getClinicReservationList = () => {
  return client.get(`reservation_clinic`)
}

// 医療機関予約（当該医療機関）取得
export const getClinicReservationListByClinic = (facilityId: string) => {
  return client.get(`reservation_clinic/by_clinic/${facilityId}`)
}

// 医療機関予約（当該ユーザー）取得
export const getClinicReservationListByUser = (user_id: string) => {
  return client.get(`reservation_clinic/by_user/${user_id}`)
}

// 医療機関予約（未紐付け）取得
export const getNoRelateClinicReservation = (user_id: string) => {
  return client.get(`reservation_clinic/no_relation/${user_id}`)
}

// 薬局予約（1件）取得
export const getPharmacyReservation = (id: string) => {
  return client.get(`reservation_pharmacy/${id}`)
}

// 薬局予約（1件）取得 (ID以外条件付与)
export const showPharmacyReservation = (id: string, params: any = null) => {
  return client.post(`reservation_pharmacy/show_by_pharmacy/${id}`, params)
}

// 薬局予約（全件）予約取得
export const getPharmacyReservationList = () => {
  return client.get(`reservation_pharmacy`)
}

// 薬局予約（当該医療機関）取得
export const getPharmacyReservationListByPharmacy = (facilityId: string) => {
  return client.get(`reservation_pharmacy/by_pharmacy/${facilityId}`)
}

// 施設予約（当該医療機関）取得
export const getReservationListByFacility = (facilityId: string) => {
  return client.get(`reservations/by_facility/${facilityId}`)
}

// 予約連携パラメータ暗号化
export const encryptedParam = (params: any) => {
  return client.post(`reservations/encrypted_param`, {data: params})
}

//処方せん情報登録（新規）
export const createPrescriptions = (params: any) => {
  return client.post(
    `prescriptions`,
    params,
    {  headers: { "content-type": "multipart/form-data" } })
}

//処方せん情報更新
export const updatePrescriptions = (id: number, params: any) => {
  return client.put(
    `prescriptions/${id}`,
    params,
    {  headers: { "content-type": "multipart/form-data" } })
}

//処方せんステータス更新
export const updatePrescriptionStatus = (id: number, params: any) => {
  return client.put(`prescriptions/update_status/${id}`, params)
}

//処方せん情報取得(by 診療予約ID)
export const getPrescriptionByClinicReservation = (clinic_reservation_id: number) => {
  return client.get(`prescriptions/by_clinic_reservation/${clinic_reservation_id}`)
}

//薬局の予約に紐づく処方せん情報取得
export const getPrescriptionByPharmacyReservation = (reservation_id: number) => {
  return client.get(`prescriptions/by_pharmacy_reservation/${reservation_id}`)
}

// 処方せんファイル削除
export const deletePrescriptionFile = (id: number, fileName: string) => {
  const urlParts = fileName.split('/');
  const actualFileName = urlParts[urlParts.length - 1];
  return client.delete(`prescriptions/delete_file/${id}/${actualFileName}`);
}

// 診療明細情報取得
export const getReceiptDetailByReservation = (reservation_id: number) => {
  return client.get(`reservations/receipt_detail_by_reservation/${reservation_id}`)
}

// 診療明細情報登録（新規）
export const createReceiptDetail = (params: any) => {
  return client.post(
    `reservations/create_receipt_detail`,
    params,
    {  headers: { "content-type": "multipart/form-data" } })
}

// 診療明細情報更新
export const updateReceiptDetail = (id: number, params: any) => {
  return client.put(
    `reservations/update_receipt_detail/${id}`,
    params,
    {  headers: { "content-type": "multipart/form-data" } })
}

// 診療明細削除
export const deleteReceiptDetail = (id: number, fileName: string) => {
  const urlParts = fileName.split('/');
  const actualFileName = urlParts[urlParts.length - 1];
  return client.delete(`reservations/delete_receipt_detail/${id}/${actualFileName}`)
}

// 医療機関_関連予約情報取得
export const getClinicReservationRelation = (reservation_id: number) => {
  return client.get(`relate_pharmacy_clinic_reserves/by_clinic_reservation/${reservation_id}`)
}

// 薬局_関連予約情報取得
export const getPharmacyReservationRelation = (reservation_id: number) => {
  return client.get(`relate_pharmacy_clinic_reserves/by_pharmacy_reservation/${reservation_id}`)
}

// 薬局予約情報取得（処方せん情報含む）
export const getPharmacyReservationWithPrescription = (reservation_id: number) => {
  return client.get(`reservation_pharmacy/with_prescription/${reservation_id}`)
}
