import React, { useState } from 'react';
// import {
//   AlertColor
// } from '@mui/material';
// import { useForm, SubmitHandler } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
import { ClinicSchema, PharmacySchema } from '../schema/FacilitySchema';

import LayoutWithMenu from '../LayoutWithMenu';
import FacilityEditCommon from './FacilityEditCommon';

// import { useLocation, useNavigate } from 'react-router-dom';
// import { styles } from '../style/commonStyles';

// import client from '../../lib/api/client';
// import { getClinicDetail } from '../../lib/api/clinic';
// import { Snackbar } from '../parts/Snackbar';
import { FacilityType } from "../../utils/Constants";
import { useAuthFacilityStaffContext } from 'contexts/AuthFacilityStaffContext';

const FacilityEdit: React.FC = () => {
  const authFacilityStaff = useAuthFacilityStaffContext().authFacilityStaff;
  const facilityId = authFacilityStaff?.facilityId.toString() || '';
  const facilityType = authFacilityStaff?.facilityType

  // const location = useLocation();
  // location.stateに型アサーションを適用
  // const state = location.state as { facilityData?: FacilityFormData };

  // const [facilityId, setFacilityId] = useState<string>('');
  // const [initialData, setInitialData] = useState<FacilityFormData>(new FacilityFormData());

  // const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  // const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  // const [severity, setSeverity] = useState<AlertColor>('success');

  // useEffect(() => {
  //   setFacilityId(staffFacilityId);
  // }, []); 

  // const useFormProps = useForm({
  //   mode: 'all',
  //   resolver: yupResolver(FacilitySchema),
  //   defaultValues: initialData ?? {}
  // })

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (initialData) {
  //     reset(initialData); // フォームに初期データを設定
  //   }
  // }, [initialData, reset]);
  
  // // Snackbar表示プロパティ設定
  // const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
  //   setSeverity(severity);
  //   setSnackbarMessage(message);
  //   setSnackbarOpen(isOpen);
  // }

  // // フォーム送信時の処理
  // const onSubmit: SubmitHandler<FacilityFormData> = async (data) => {
  //   // 施設情報更新
  //   try {
  //     switch (facilityType) {
  //       case FacilityType.clinic:
  //         await client.put(`/clinic/${facilityId}`, data);
  //         setSnackbarProps('success', '医療機関情報が更新されました', true);
  //         return
  //       case FacilityType.pharmacy:
  //         // await client.put(`/clinic/${facilityId}`, data);
  //         setSnackbarProps('success', '薬局情報が更新されました', true);
  //         return
  //       default:
  //         throw('未定義の施設タイプ');
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     setSnackbarProps('error', '施設情報の更新に失敗しました', true);
  //   }
  // }

  return (
    <LayoutWithMenu pageTitle="基本情報変更">
      <FacilityEditCommon
        // onSubmit={onSubmit}
        // initialData={initialData}
        facilityId={facilityId}
        facilityType={facilityType}
        schema={ facilityType === FacilityType.clinic ? ClinicSchema : PharmacySchema}
        // snackbarOpen={snackbarOpen}
        // setSnackbarOpen={setSnackbarOpen}
        // snackbarMessage={snackbarMessage}
        // severity={severity}
      />
    </LayoutWithMenu>
  );
}
export default FacilityEdit