import LayoutWithMenu from "./LayoutWithMenu";
import {
  Button,
  Grid,
  FormHelperText,
  Typography,
  Container,
  Box,
  Link,
} from '@mui/material';
import { styles } from "./style/commonStyles";
import React, { useEffect, useState } from "react";
import { ButtonCaption, Messages } from "../utils/Constants";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import { useUserInfoRegistered } from '../hooks/useUserInfoRegistered'
import { PharmacyFormData } from "./schema/FacilitySchema";
import { getPharmacyDetail } from "../lib/api/pharmacy";
import {
  LocalHospital as HospitalIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
  AccessTime as ClockIcon,
  Info as InfoIcon,
  Note as NoteIcon,
  Medication as MedicinesIcon,
  Comment as CommentIcon
} from '@mui/icons-material';

const customStyles = {
  gridContainer: {
    borderBottom: '1px solid lightGrey',
    fontSize: 14,
    textDecoration: 'none',
    color: '#000000',
    py: 1,
    backgroundColor: "#F5FAFD !important",
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: 'center', // 行の高さを揃えるために追加
    marginTop: 0,
    marginLeft: '0 !important',
  },
  gridItem: {
    alignContent: 'center',
    backgroundColor: "#F5FAFD !important",
    display: 'flex', // アイテムを中央揃えにするために追加
    alignItems: 'center', // アイテムを中央揃えにするために追加
    paddingLeft: '0 !important'
  },
  boldLabel: {
    fontWeight: 600, // 項目名を太字にする
  },
  linkStyle: {
    cursor: 'pointer',
    color: '#003642', // リンクの色
    textDecoration: 'none', // アンダーラインを消す
    '&:hover': {
      textDecoration: 'underline', // ホバー時にアンダーラインを表示
      color: '#1aa3c9'
    },
    fontWeight: 400,
  },
  departmentLabel: {
    fontSize: 12,
    marginTop: 3,
    marginLeft: 30,
  },
  typographyPageTitle: {
    fontSize: '32px', // タイトルのフォントサイズを変更
    marginBottom: '20px',
  },
  container: {
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',
    textAlign: 'center !important',
  },
  icon: {
    width: '20px',
    height: '20px',
    marginRight: '0px',
    color: '#32AFDA',
    paddingLeft: '5px'
  },
  centeredText: {
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10%', // ボタン間の間隔を設定
    width: '100%',
    marginTop: '30px'
  },
  button: {
    width: '200px', // ボタンの幅を指定（必要に応じて調整）
    margin: '0',  // ボタンのマージンをリセット
  }
}

// 各行に表示するアイコンのリストを作成
const iconsList = [
  MedicinesIcon,
  HospitalIcon,
  PhoneIcon,
  LocationIcon,
  ClockIcon,
  InfoIcon,
  NoteIcon,
  CommentIcon
];

const PharmacyDetail = () => {
  const { id } = useParams();
  const navigation = useNavigate()
  const [formData, setFormData] = useState<PharmacyFormData>(new PharmacyFormData());

  // ユーザー情報が登録されているか
  const {isUserInfoRegistered, userErrorMessage, userNotificationMessage} = useUserInfoRegistered()
  // エラー表示
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    initProc();
  }, []);

  const initProc = async () => {
    await displayData();
    await isUserInfoRegistered();
  }

  const displayData = async () => {
    if (!id) return;
    const result = await getPharmacyDetail(id);
    const data = result.data;
    setFormData(
      {
        name: data.name,
        nameShort: data.nameShort,
        kana: data.kana,
        department: data.department,
        zip: data.zip,
        address: data.address,
        tel: data.tel,
        note: data.note || '',  // ここで空文字列にフォールバック
        operatingHours: data.operatingHours,
        information: data.information,
        directId: data.directId
      } as PharmacyFormData
    );
    // direct_id未設定の場合はボタンを押下させない
    if (!data.directId)
      setErrorMessage(Messages.cannotReservation)
  }

  // 予約紐付け画面遷移
  const moveToRelation = (data: PharmacyFormData) => {
    navigation(
      `/pharmacy/reservation/`,
      { state: { id: data.id, name: data.nameShort || data.name, directId: data.directId } });
  }

  // 詳細項目Grid
  const gridItemWithLabel = (label: string, value: string, IconComponent: React.ComponentType) => {
    return (
      <Grid container sx={[customStyles.gridContainer, { marginTop: '0', paddingTop: '0' }]} spacing={2}>
        <Grid item xs={1} sx={[customStyles.gridItem, { paddingLeft: '5px' }]}>
          <Box component={IconComponent} sx={customStyles.icon} />
        </Grid>
        <Grid item xs={3} sx={customStyles.gridItem}>
          <Typography sx={customStyles.boldLabel}>{label}</Typography>
        </Grid>
        <Grid item xs={8} sx={customStyles.gridItem}>
          {value}
        </Grid>
      </Grid>
    )
  }

  return (
    <LayoutWithMenu pageTitle={formData.name || "不明な薬局"}>
      <Box sx={customStyles.container}>
        <Link
          component={RouterLink}
          to={`/pharmacy/${id}/commerce`}
          sx={[customStyles.linkStyle, customStyles.boldLabel]}
        >
          {'特定商取引法に基づく表示'}
        </Link>
        <FormHelperText error={true} sx={{whiteSpace: 'pre-line', pt:3}}>{userErrorMessage}</FormHelperText>
        <FormHelperText sx={{whiteSpace: 'pre-line'}}>{userNotificationMessage}</FormHelperText>
      </Box>
      <Container maxWidth={false} >
        {gridItemWithLabel('名称', formData.name, iconsList[1])}
        {gridItemWithLabel('略称', formData.nameShort, iconsList[1])}
        {gridItemWithLabel('所在地', `〒${formData.zip || ''}\n${formData.address || ''}`, iconsList[3])}
        {gridItemWithLabel('電話番号', formData.tel, iconsList[2])}
        {gridItemWithLabel('開局時間', formData.operatingHours, iconsList[4])}
        {gridItemWithLabel('紹介コメント', formData.information || '', iconsList[7])}
        {gridItemWithLabel('備考', formData.note || '', iconsList[6])}

        <Box sx={customStyles.buttonContainer}>
          <Button
            sx={{
              color: 'black',
              borderColor: 'black', // 枠線の色
              '&:hover': {
                backgroundColor: 'transparent', // ホバー時の背景色を透明に
                borderColor: 'black', // ホバー時も枠線を黒に保つ
              },
              width: '200px',
              margin: '0',
            }}

            color="secondary"
            variant="outlined"
            onClick={() => navigation(-1)}
          >
            {ButtonCaption.back}
          </Button>


          <Button
            sx={{
              backgroundColor: '#21bddb',
              boxShadow: 'none',
              fontSize: { xs: '0.75rem', sm: '0.875rem' },
              fontWeight: 600,
              '&:hover': {
                backgroundColor: '#1aa3c9',
                boxShadow: 'none',
              },
              width: '200px',
              margin: '0',
            }}
            color="primary"
            variant="contained"
            onClick={() => moveToRelation(formData)}
            disabled={errorMessage !== '' || userErrorMessage !== ''}
          >
            {ButtonCaption.reservation}
          </Button>
        </Box>
      </Container>
    </LayoutWithMenu>
  )
}
export default PharmacyDetail;
