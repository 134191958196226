import { useState } from "react"
import {
  Box,
  Button,
  Stack,
  Grid,
  TextField,
  Link,
  Typography,
  AlertColor,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordUpdateFormData, PasswordUpdateSchema } from '../schema/PasswordUpdateSchema';

import { Snackbar } from '../parts/Snackbar';
import { ButtonCaption, Sex } from "../../utils/Constants";

import { styles } from "../style/commonStyles";
import LayoutWithMenu from '../LayoutWithMenu';

import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useAuthFacilityStaffContext } from '../../contexts/AuthFacilityStaffContext'

const FacilityStaffPasswordUpdate = () => {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search)
  const headers = {
    'access-token': searchParams.get('access-token'),
    'client': searchParams.get('client'),
    'uid': searchParams.get('uid'),
  }

  const passwordUpdate = useAuthFacilityStaffContext().passwordUpdate

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  const [updated, setUpdated] = useState(false)

  const onSubmit: SubmitHandler<PasswordUpdateFormData> = (data) => {
    const password = data.password
    const password_confirmation = data.password_confirmation
    if ( !(password && password_confirmation) ) {
      console.log("TODO: validation実装: password or password_confirmation is empty!")
      return false
    }
    const params: PasswordUpdateFormData = {password, password_confirmation}

    passwordUpdate(
      params, { headers },
      () => {
        setSnackbarProps('success', 'パスワードが更新されました', true);
        setUpdated(true)
      }
    )
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PasswordUpdateFormData>({
    resolver: yupResolver(PasswordUpdateSchema),
  })

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }

  return (
    <LayoutWithMenu hideNavLinks={true} navColor={"#ffffff"}>
      {updated ? (
        <>
          <Stack spacing={5} sx={{ mt: 10, alignItems: "center" }}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Grid item xs={12}>
                <img
                  src="/images/login/login_logo@2x.png"
                  alt="Bizcare"
                  style={{ width: "179px", height: "72px" }}
                />
              </Grid>
            </Grid>
            <Typography sx={styles.TypographyPageTitle} align="center">
              パスワードの再発行
            </Typography>
            <Typography sx={{ m: 3 }} variant="subtitle2" align="center">
              パスワードを更新しました。
            </Typography>
            <Box display="flex" justifyContent="center" width="50%">
              <Button
                sx={{
                  ...styles.buttonSingle,
                  color: "#70787C",
                  borderColor: "#70787C",
                  width: "60%",
                  "&:hover": {
                    boxShadow: (theme) => theme.shadows[4],
                  },
                }}
                variant="outlined"
              >
                <Link
                  component={RouterLink}
                  to="/facility/login"
                  variant="body2"
                  style={{ textDecoration: "none" }}
                >
                  ログインページ
                </Link>
              </Button>
            </Box>
          </Stack>
        </>
      ) : (
        <>
          <Stack spacing={5} sx={{ mt: 10 }}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Grid item xs={12}>
                <img
                  src="/images/login/login_logo@2x.png"
                  alt="Bizcare"
                  style={{ width: "179px", height: "72px" }}
                />
              </Grid>
            </Grid>
            <Typography sx={styles.TypographyPageTitle} align="center">
              パスワードの再設定
            </Typography>
            <Typography sx={{ m: 3 }} variant="subtitle2" align="center">
              新しいパスワードを登録してください。
            </Typography>
          </Stack>
          <Stack spacing={7}>
            <Stack spacing={4} sx={{ pt: 3 }}>
              <TextField
                required
                label="パスワード"
                type="password"
                InputLabelProps={{ shrink: true }}
                {...register("password")}
                error={"password" in errors}
                helperText={errors.password?.message}
                sx={styles.TextField}
              />

              <TextField
                required
                label="パスワード（確認）"
                type="password"
                InputLabelProps={{ shrink: true }}
                {...register("password_confirmation")}
                error={"password_confirmation" in errors}
                helperText={errors.password_confirmation?.message}
                sx={styles.TextField}
              />
            </Stack>

            <Stack spacing={2}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="row"
              >
                <Grid item xs={6}>
                  <Button
                    sx={{
                      ...styles.buttonSingle,
                      color: "#70787C",
                      borderColor: "#70787C",
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.action.hover,
                        boxShadow: (theme) => theme.shadows[4],
                        borderColor: "#5a6169",
                        color: "#5a6169",
                      },
                    }}
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  >
                    {ButtonCaption.cancel}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    sx={styles.buttonSingle}
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {ButtonCaption.send}
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </>
      )}
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
}
export default FacilityStaffPasswordUpdate
