import {Chip as MuiChip} from "@mui/material";

export type ChipType = 'info' | 'warn' | 'error'
  
type Props = {
  label: string,
  type?: ChipType,
}

const styles = {
  chipInfo: {
    ml: 1,
    mb: 0.2,
    backgroundColor: '#1976d2',
    color: 'white'
  },
  chipWarn: {
    ml: 1,
    mb: 0.2,
    backgroundColor: '#F6AA00',
    color: 'white'
  }
}

const Chip = (props: Props) => {

  const chipStyle = () => {
    switch (props.type) {
      case 'info': return styles.chipInfo
      case 'warn': return styles.chipWarn
      default: return styles.chipInfo
    }
  }

  return (
    <MuiChip
      label={props.label}
      size="small"
      variant="filled"
      sx={chipStyle()}
    />
  )
}
export default Chip