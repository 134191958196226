import React, { useEffect, useState } from 'react'
import {
  Button,
  Grid,
  Typography,
  Container,
  Box,
} from '@mui/material';

import { useParams, useNavigate } from 'react-router-dom';
import { FacilityCommerce } from '../interfaces/index';
import { FacilityCommerceFormData } from './schema/FacilityCommerceSchema';
import LayoutWithMenu from './LayoutWithMenu';

import { getCommerceByFacility } from '../lib/api/facility_commerce';

import { ButtonCaption } from "../utils/Constants";

const customStyles = {
  gridContainer: {
    borderBottom: '1px solid lightGrey',
    fontSize: 14,
    textDecoration: 'none',
    color: '#000000',
    py: 1,
    backgroundColor: "#F5FAFD !important",
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: 'center', // 行の高さを揃えるために追加
    marginTop: 0,
    marginLeft: '0 !important', 
    whiteSpace: 'pre-line',
  },
  gridItem: {
    alignContent: 'center',
    backgroundColor: "#F5FAFD !important",
    display: 'flex', // アイテムを中央揃えにするために追加
    alignItems: 'center', // アイテムを中央揃えにするために追加
    paddingLeft: '0 !important'
  },
  container: {
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px', 
    textAlign: 'center !important',
  },
  boldLabel: {
    fontWeight: 600, // 項目名を太字にする
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10%', // ボタン間の間隔を設定
    width: '100%',
    marginTop: '30px'
  },
}

const Commerce = () => {
  const { facility_id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FacilityCommerceFormData>();

  useEffect(() => {
    (async() => {
      await displayData();
    })()
  },[facility_id])

  //　表示内容取得＆設定
  const displayData = async () => {
    if (!facility_id) return;
    const result = await getCommerceByFacility(Number(facility_id))
    const data = result.data as FacilityCommerce;
    if (data) {
      setFormData(
        {
          companyName: data.companyName,
          executiveManager: data.executiveManager,
          address: data.address,
          tel: data.tel,
          mail: data.mail,
          sellingPricePolicy: data.sellingPricePolicy,
          expenses: data.expenses,
          cancelPolicy: data.cancelPolicy,
          paymentOption: data.paymentOption,
          paymentTiming: data.paymentTiming,
          serviceProvisionDate: data.serviceProvisionDate
        } as FacilityCommerceFormData
     );
    }
  }

  // 詳細項目Grid
  const gridItemWithLabel = (label: string, value: string) => {
    console.log(label, value)
    return (
    <Grid container sx={[customStyles.gridContainer, {marginTop: '0',paddingTop: '0'}]} spacing={2}>
        <Grid item xs={1} sx={[customStyles.gridItem, { paddingLeft: '5px' }]} />
        <Grid item xs={3} sx={customStyles.gridItem}>
          <Typography sx={customStyles.boldLabel}>{label}</Typography>
        </Grid>
        <Grid item xs={8} sx={customStyles.gridItem}>
          {value}
        </Grid>
      </Grid>
    );
  }

  return (
    <LayoutWithMenu pageTitle={'特定商取引法に基づく表示'} >
      <Container maxWidth={false} >
        {!formData && <Box sx={customStyles.container}>{"データがありません"}</Box>}
        { formData &&
          <React.Fragment>
            {gridItemWithLabel('事業者名', formData.companyName)}
            {gridItemWithLabel('代表者名', formData.executiveManager)}
            {gridItemWithLabel('所在地', formData.address)}
            {gridItemWithLabel('電話番号', formData.tel)}
            {gridItemWithLabel('電子メール', formData.mail)}
            {gridItemWithLabel('販売価格', formData.sellingPricePolicy)}
            {gridItemWithLabel(`サービス利用に\n必要な費用`, formData.expenses)}
            {gridItemWithLabel('返品条件', formData.cancelPolicy)}
            {gridItemWithLabel('支払方法', formData.paymentOption)}
            {gridItemWithLabel('支払時期', formData.paymentTiming)}
            {gridItemWithLabel('商品引渡・\n役務提供時期', formData.serviceProvisionDate)}
          </React.Fragment>
        }

        <Box sx={customStyles.buttonContainer}>
          <Button
            sx={{
              color: 'black',
              borderColor: 'black',
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: 'black',
              },
              width: '200px',
              margin: '0',
            }}
            color="secondary"
            variant="outlined"
            onClick={() => navigate(-1)}
          >
            {ButtonCaption.back}
          </Button>
        </Box>
      </Container>
    </LayoutWithMenu>
  )
}
export default Commerce
