import React, {useEffect, useMemo, useState} from "react";
import {Button, Chip, Grid, Box, Link, Tabs, Tab, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import LayoutWithMenu from "../LayoutWithMenu";
import {FacilityReservation} from "../../interfaces";
import {getReservationListByFacility} from "../../lib/api/reservation";
import { useAuthFacilityStaffContext } from '../../contexts/AuthFacilityStaffContext'
import {ButtonCaption} from "../../utils/Constants";
import {formattedDatetime} from "../../utils/helper";
import {ReservationStatus} from "../../utils/Constants";

const styles = {
  GridContainer: {
    borderBottom: 1,
    borderColor: "lightGrey",
    py: 1,
    fontSize: 16,
    textDecoration: "none",
    color: "#003642",
    backgroundColor: "#F5FAFD",
    paddingTop: 1,
    paddingBottom: 1,
  },
  GridItem: {
    alignContent: "center",
    backgroundColor: "#F5FAFD",
  },
  GridText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#003642",
  },
  TabText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "0.1px",
    textAlign: "center",
    color: "#003642",
  },
  StrikeThrough: {
    position: "relative",
    "&::after": {
      width: "100%",
      height: "100%",
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      background:
        "linear-gradient(transparent 47%, #000000 47%, #000000 52%, transparent 52%)",
    },
  },
};

const FacilityReservationList = () => {
  const [reservations, setReservations] = useState<FacilityReservation[]>([]);
  const authFacilityStaff = useAuthFacilityStaffContext().authFacilityStaff;
  const navigation = useNavigate();

  const [tabValue, setTabValue] = useState<"today" | "other">("today");

  useEffect(() => {
    fetchReservations();
  }, [authFacilityStaff]);

  // 初期表示
  const fetchReservations = async () => {
    if (!authFacilityStaff) {
      return;
    }

    try {
      const response = await getReservationListByFacility(
        authFacilityStaff.facilityId.toString()
      );
      setReservations(response.data);
    } catch (error) {
      console.error("予約一覧の取得に失敗しました", error);
    }
  };

  // 診療明細登録画面遷移
  const moveToReceiptDetailSending = (
    reservation_id: number,
    user_id: number
  ) => {
    navigation(`receipt_detail/send`, {
      state: { reservation_id: reservation_id, user_id: user_id },
    });
  };

  // 処方せん登録画面遷移
  const moveToPrescriptionSending = (
    reservation_id: number,
    user_id: number
  ) => {
    navigation(`prescription/send`, {
      state: { reservation_id: reservation_id, user_id: user_id },
    });
  };

  // 処方せん一覧画面遷移
  const moveToPrescriptionList = (reservation_id: number) => {
    navigation(`prescription/list`, {
      state: { pharmacyReservationId: reservation_id },
    });
  };

  // 詳細画面遷移
  const moveToDetail = (id: number) => {
    if (authFacilityStaff && authFacilityStaff.facilityType === "Pharmacy") {
      navigation(`pharmacy/${id}`);
    } else if (authFacilityStaff && authFacilityStaff.facilityType === "Clinic") {
      navigation(`clinic/${id}`);
    }
  };

  // クリニックスタッフログイン時操作ボタン
  const clinicStaffOperation = (reservation: FacilityReservation) => {
    return (
      <>
        <Grid container item xs={2} sx={styles.GridItem}>
          <Button
            sx={{
              backgroundColor: "#21bddb",
              boxShadow: "none",
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#1aa3c9",
                boxShadow: "none",
              },
            }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() =>
              moveToReceiptDetailSending(reservation.id, reservation.userId)
            }
          >
            {ButtonCaption.reservationDetail}
          </Button>
        </Grid>
        <Grid container item xs={2} sx={styles.GridItem}>
          <Button
            sx={{
              backgroundColor: "#21bddb",
              boxShadow: "none",
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#1aa3c9",
                boxShadow: "none",
              },
            }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() =>
              moveToPrescriptionSending(reservation.id, reservation.userId)
            }
          >
            {ButtonCaption.reservationPrescription}
          </Button>
        </Grid>
      </>
    );
  };

  // 薬局スタッフログイン時操作ボタン
  const pharmacyStaffOperation = (reservation: FacilityReservation) => {
    return (
      <>
        <Grid container item xs={4} sx={styles.GridItem}>
          {/* 20240410 暫定対応: 薬局の処方せん確認はメニューから行う */}
          <Button
            sx={{
              backgroundColor: "#21bddb",
              boxShadow: "none",
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#1aa3c9",
                boxShadow: "none",
              },
            }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => moveToPrescriptionList(reservation.id)}
          >
            {ButtonCaption.reservationPrescription}
          </Button>
        </Grid>
      </>
    );
  };

  const filteredReservations = useMemo(() => {
    const isToday = (reservation: FacilityReservation) =>
      new Date().toDateString() ===
      new Date(reservation.reservationTime).toDateString();
    return tabValue === "today"
      ? reservations.filter((reservation) => isToday(reservation))
      : reservations.filter((reservation) => !isToday(reservation));
  }, [tabValue, reservations]);

  return (
    <LayoutWithMenu>
      {reservations.length === 0 && "予約はありません"}
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={tabValue}
          onChange={(_, value) => setTabValue(value)}
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{
            ".MuiTab-root": {
              backgroundColor: "#F5FAFD",
              minWidth: 150,
              "&.Mui-selected": {
                // 選択されたタブのスタイル
                backgroundColor: "#87D1EA",
                color: "#000",
              },
            },
            marginTop: 8,
          }}
        >
          <Tab label="本日の予約" value="today" sx={styles.TabText} />
          <Tab label="その他の予約" value="other" sx={styles.TabText} />
        </Tabs>

        {filteredReservations.map((reservation) =>
          reservation.isCanceled ? (
            <Grid container key={reservation.id} sx={styles.GridContainer}>
              <Box component="div" display="flex" sx={{ gap: "2rem" }}>
                <Reservation reservation={reservation} />
              </Box>
            </Grid>
          ) : (
            <Link
              onClick={() => moveToDetail(reservation.id)}
              style={{ textDecoration: "none" }}
              sx={{
                textDecoration: "none", // 下線を消す
                cursor: "pointer", // リンクにカーソルが乗ったときの形状を指定
                color: "inherit", // 親要素から色を継承
              }}
            >
              <Grid container key={reservation.id} sx={styles.GridContainer}>
                <Box component="div" display="flex" sx={{ gap: "2rem" }}>
                  <Reservation reservation={reservation} />
                </Box>
              </Grid>
            </Link>
          )
        )}
      </Box>
    </LayoutWithMenu>
  );
}

const Reservation: React.FC<{ reservation: FacilityReservation }> = ({ reservation }) => {

  // const authFacilityStaff = useAuthFacilityStaffContext().authFacilityStaff
  const navigation = useNavigate();

  // if (!authFacilityStaff) {
  //   return null;
  // }

  // // 詳細画面遷移
  // const moveToDetail = (id: number) => {
  //   if (authFacilityStaff.facilityType === 'Pharmacy') {
  //     navigation(`pharmacy/${id}`)
  //   } else if (authFacilityStaff.facilityType === 'Clinic') {
  //     navigation(`clinic/${id}`)
  //   }
  // }

  if (reservation.isCanceled) {
    return (
      <Box display="flex" flexDirection="row" p={1}>
        {/* 取り消し線を適用したいテキスト部分を別のBoxで囲む */}
        <Box sx={{...styles.StrikeThrough, display: "flex", alignItems: "center"}}>
          <Typography sx={{ ...styles.GridText, width: "130px", marginRight: 0.5 }}>
            {reservation.reservationTime
              ? formattedDatetime(reservation.reservationTime)
                  .split(" ")[0]
                  .replace(/\(.+?\)/, "") // 括弧と曜日を削除
              : ""}
          </Typography>
          <Typography
            sx={{
              ...styles.GridText,
              width: "50px",
              textAlign: "center",
              marginRight: 5,
            }}
          >
            {reservation.reservationTime
              ? formattedDatetime(reservation.reservationTime).split(" ")[1] : ""}
          </Typography>
          <Typography sx={styles.GridText}>
            {reservation.user.name}
          </Typography>
        </Box>
        {/* 取り消し線を適用しないChip部分 */}
        <Chip
          label="キャンセル"
          variant="outlined"
          sx={{
            marginLeft: "24px",
            borderRadius: "8px",
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px",
            fontSize: "14px",
            fontFamily: "Roboto",
            color: "#003642",
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <Box display="flex" flexDirection="row" p={1} alignItems="center">
        <Typography
          sx={{ ...styles.GridText, width: "130px", marginRight: 0.5 }}
        >
          {reservation.reservationTime
            ? formattedDatetime(reservation.reservationTime)
                .split(" ")[0]
                .replace(/\(.+?\)/, "") // 括弧と曜日を削除
            : ""}
        </Typography>
        <Typography
          sx={{
            ...styles.GridText,
            width: "50px",
            textAlign: "center",
            marginRight: 5,
          }}
        >
          {reservation.reservationTime
            ? formattedDatetime(reservation.reservationTime).split(" ")[1]
            : ""}
        </Typography>
        <Typography sx={styles.GridText}>{reservation.user.name}</Typography>
        {reservation.status === ReservationStatus.Completed && (
          <Chip
            label="完了"
            variant="outlined"
            sx={{
              marginLeft: "24px",
              borderRadius: "8px",
              border: "none",
              fontSize: "14px",
              fontFamily: "Roboto",
              color: "#003642",
              backgroundColor: "#CEE6F0",
            }}
          />
        )}
      </Box>
    </>
  );
}

export default FacilityReservationList;
