import { useEffect, useState } from 'react';
import { Snackbar } from './parts/Snackbar';
import LayoutWithMenu from './LayoutWithMenu';
import ImageModal from './parts/ImageModal';
import {
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AlertColor,
  Grid,
  Button,
} from "@mui/material";

import {
  getReservationWithFacility,
} from '../lib/api/reservation';

import { Reservation } from '../interfaces/index';
import { useAuthUserContext } from '../contexts/AuthUserContext'
import { styles as commonStyles } from './style/commonStyles';
import { formattedDatetime } from '../utils/helper';
import { ButtonCaption } from "../utils/Constants";

const ReceiptDetail = () => {
  const authUser = useAuthUserContext().authUser
  const location = useLocation();
  const navigation = useNavigate();
  const [params] = useState(location.state);
  const [reservation, setReservation] = useState<Reservation>();

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage] = useState<string>('');
  const [severity] = useState<AlertColor>('success');

  useEffect(() => {
    getReservationListByUserData();
  },[authUser])

  // 予約情報取得
  const getReservationListByUserData = async () => {
    if (!authUser) {
      return;
    }

    try {
      const response = await getReservationWithFacility(params.reservation_id.toString());
      setReservation(response.data);
    } catch (error) {
      console.error('予約一覧の取得に失敗しました', error);
    }
  };

  // style定義
  const styles = {
    deleteIcon: {
      position: 'absolute',
      top: 10,
      right: 0,
    },
    previewImageGrid: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      position: 'relative',
    },
    ...commonStyles,
    GridContainer: {
      borderColor: 'lightGrey',
      py: 1,
      fontSize: 16,
      textDecoration: 'none',
      color: '#000000',
      paddingBottom: 5
    },
    GridItem: {
      alignContent: 'center',
    }
  }

    // Modal表示管理
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [modalImgSrc, setModalImgSrc] = useState<string>('')

    // Modal表示内容セット
    const setModalContent = (url: string) => {
      setModalImgSrc(url)
      setModalOpen(true)
    }

  return (
    <LayoutWithMenu>
      <Typography
        sx={{ ...commonStyles.TypographyPageTitle, mt: 8, textAlign: "left" }}
      >
        診療明細
      </Typography>

      {reservation && (
        <Grid container sx={styles.GridContainer}>
          <Grid container item xs={7} sx={styles.GridItem}>
            <div>
              <p>
                予約：{" "}
                {reservation.reservationTime
                  ? formattedDatetime(reservation.reservationTime)
                  : ""}
              </p>
              <p>{reservation.facility.name}</p>
            </div>
          </Grid>

          {reservation.receiptFiles && reservation.receiptFiles.length > 0 ? (
            <Grid container spacing={2}>
              {reservation.receiptFiles.map((image, index) => (
                <Grid item xs={6} md={4} sx={styles.GridItem} key={index}>
                  <div
                    style={{
                      width: "150px",
                      height: "150px",
                      position: "relative",
                    }}
                    onClick={
                      !image.url.endsWith(".pdf")
                        ? () => setModalContent(image.url)
                        : undefined
                    }
                  >
                    {image.url.endsWith(".pdf") ? (
                      <>
                        <object
                          data={image.url}
                          type="application/pdf"
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                          aria-label="PDFを開く"
                        />
                        <a
                          href={image.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "blue",
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                          }}
                        >
                          PDFを開く
                        </a>
                      </>
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                        src={image.url}
                        alt="診療明細"
                      />
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container item xs={12} sx={styles.GridItem}>
              <p>診療明細はありません</p>
            </Grid>
          )}
        </Grid>
      )}

      {/* 戻るボタン */}
      <Grid container pt={3} justifyContent="center">
        <Grid item xs={5}>
          <Button
            sx={{
              color: "black",
              borderColor: "black",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "black",
              },
              width: "100%",
            }}
            color="secondary"
            variant="outlined"
            onClick={() => navigation(-1)}
          >
            {ButtonCaption.back}
          </Button>
        </Grid>
      </Grid>

      <ImageModal
        open={modalOpen}
        setOpen={setModalOpen}
        imgSrc={modalImgSrc}
      />
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
}

export default ReceiptDetail;
