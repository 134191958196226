import client from "lib/api/client"

// ユーザー一覧取得
export const getUserList = () => {
  return client.get(`users`)
}

// ユーザー詳細取得
export const getUserDetail = (id: string) => {
  return client.get(`users/${id}`)
}

// 画像登録
export const registerImages = (id: string, param: any) => {
  return client.put(
    `users/${id}`,
    param,
    {  headers: { "content-type": "multipart/form-data" } }
  )
}

// ユーザー一覧取得
export const getReservedUserList = (facilityId: string) => {
  return client.get(`users/by_facility/${facilityId}`)
}

export const deleteHicardImage = (id: string, fileName: string) => {
  const urlParts = fileName.split('/');
  const actualFileName = urlParts[urlParts.length - 1];
  return client.delete(`users/delete_hicard_image/${id}/${actualFileName}`)
}

export const deleteNotebookImage = (id: string, fileName: string) => {
  const urlParts = fileName.split('/');
  const actualFileName = urlParts[urlParts.length - 1];
  return client.delete(`users/delete_notebook_image/${id}/${actualFileName}`)
}

