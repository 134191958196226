import { useState } from "react"

import {
  Button,
  Stack,
  Grid,
  TextField,
  Typography,
  AlertColor,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordResetFormData, PasswordResetSchema } from './schema/PasswordResetSchema';

import { Snackbar } from './parts/Snackbar';
import { ButtonCaption } from "../utils/Constants";

import { styles } from './style/commonStyles'
import LayoutWithMenu from './LayoutWithMenu';
import { HeadlineWidhLogo } from './parts/HeadlineWidhLogo';

import { useNavigate } from 'react-router-dom';
import { useAuthUserContext } from '../contexts/AuthUserContext'

const PasswordReset = () => {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search)
  const passwordResetSuccess = searchParams.get('password_reset_success') || ''

  const passwordReset = useAuthUserContext().passwordReset

  const [sentMail, setSentMail] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  const onSubmit: SubmitHandler<PasswordResetFormData> = (data) => {
    const email = data.email
    const params: PasswordResetFormData = {email, redirect_url: `${process.env.REACT_APP_ROOT_URL}/password_update`}

    passwordReset(
      params,
      () => {
        console.log('パスワードリセット!')
        setSentMail(true)
      },
      () => {
        setSnackbarProps('warning', 'メールアドレスを確認出来ませんでした', true);
      }
    )
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PasswordResetFormData>({
    resolver: yupResolver(PasswordResetSchema),
  })

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }
  
  return (
    <LayoutWithMenu
      hideNavLinks={true}
      navColor="white"
    >
      <HeadlineWidhLogo pageTitleName="パスワードの再発行" />
      { sentMail ? <>
        <Typography  sx={{m: 3}} variant="subtitle1" align='center'>パスワードの変更依頼を受け付けました。数分以内にメールを送信いたしますので、内容をご確認ください。</Typography>
      </> : <>
        { passwordResetSuccess === 'false' &&
          <Typography  sx={{m: 3}} variant="subtitle1" align='center'>パスワード変更完了までの有効期限が切れています。<br />お手数ですが、パスワードの再発行手続きをお願いします。</Typography>
        }
        <Typography  sx={{mt: 3, mb: 2}} variant="subtitle1" align='center'>登録しているメールアドレスに仮パスワードを送信します。</Typography>
        <Stack spacing={6} sx={{pt: 3}}>
          <TextField
            required
            label="メールアドレス"
            InputLabelProps={{ shrink: true }}
            {...register('email')}
            error={"email" in errors}
            helperText={errors.email?.message}
            placeholder='メールアドレスを入力してください'
            sx={styles.TextField}
          />
          <Grid container alignItems='center' justifyContent="space-around" direction="row">
            <Grid item xs={5}>
              <Button
                sx={styles.buttonOutlined}
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {ButtonCaption.cancel}
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                sx={styles.buttonSimple} 
                onClick={handleSubmit(onSubmit)}
              >
                {ButtonCaption.send}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </>
      }
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
}
export default PasswordReset