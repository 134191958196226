import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthAdminUserContext } from '../../contexts/AuthAdminUserContext'

const SignOut = () => {
  const navigate = useNavigate();
  const signOut = useAuthAdminUserContext().signOut

  useEffect(() => {
    signOut(() => {
      // console.log('ログアウト!')
      navigate('/admin/login')
    })
  }, []);

  return (<></>)
}
export default SignOut