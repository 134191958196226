import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Stack,
  TextField,
  AlertColor
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FacilityNewFormData, FacilityNewSchema } from '../schema/FacilitySchema';

import LayoutWithMenu from '../LayoutWithMenu';

import { useNavigate } from 'react-router-dom';
import { styles } from '../style/commonStyles';

import { newClinic } from '../../lib/api/clinic';
import { newPharmacy } from '../../lib/api/pharmacy';
import { Snackbar } from '../parts/Snackbar';
import { ButtonCaption, FacilityType } from "../../utils/Constants";
import { capitalize, randomStrings } from '../../utils/helper' 

const FacilityNewAdmin: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const facilityType = capitalize(searchParams.get('facility_type') ?? 'Clinic')
  
  const [registeredId, setRegisteredId] = useState('')
  const [facilityTypeStr, setFacilityTypeStr] = useState<string>('施設')

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  useEffect(() => {
    if (facilityType === FacilityType.pharmacy) setFacilityTypeStr('薬局')
    if (facilityType === FacilityType.clinic) setFacilityTypeStr('医療機関')
  }, [facilityType])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FacilityNewSchema),
  })

  const navigate = useNavigate();

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }

  // フォーム送信時の処理
  const onSubmit: SubmitHandler<FacilityNewFormData> = async (data) => {
    if (registeredId) {
      if (facilityType === FacilityType.clinic) {
        navigate(`/admin/clinic_staff/new?facility_id=${registeredId}`);
      } else if (facilityType === FacilityType.pharmacy) {
        navigate(`/admin/pharmacy_staff/new?facility_id=${registeredId}`);
      }
      return
    }

    // 施設情報更新
    try {
      switch (facilityType) {
        case FacilityType.clinic:
          const ret = await newClinic(data) as ({data: {id: number}, message: string, status: string});
          console.log({ret})
          if (ret?.status === "SUCCESS" ) {
            setSnackbarProps('success', '医療機関情報が登録されました', true);
            if (ret.data.id) {
              setRegisteredId(ret.data.id.toString())
            }
            return
          }
          break
        case FacilityType.pharmacy:
          const retPharmacy = await newPharmacy(data) as ({data: {id: number}, message: string, status: string});
          console.log({retPharmacy})
          if (retPharmacy?.status === "SUCCESS" ) {
            setSnackbarProps('success', '薬局情報が登録されました', true);
            if (retPharmacy.data.id) {
              setRegisteredId(retPharmacy.data.id.toString())
            }
            return
          }
          break
        default:
          throw('未定義の施設タイプ');
      }
    } catch (error) {
      console.log(error)
      setSnackbarProps('error', '施設情報の登録に失敗しました', true);
    }
  }

  return (
    <LayoutWithMenu pageTitle={`${facilityTypeStr}情報の登録`}>
      <Stack spacing={3}>
        { !registeredId ? 
        <TextField
          // required
          label="施設名称"
          {...register('name', {required: true})}
          defaultValue={`新規施設-${randomStrings(4)}`}
          error={!!errors.name}
          helperText={errors.name?.message} 
          InputLabelProps={{
            shrink: true, 
          }}
        /> : <>
          続けて、施設スタッフアカウントを作成します。
        </>
        }
      </Stack>
      <Grid container pt={3}>
        <Grid item xs={3}>
          <Button
              color="secondary"
              variant="outlined"
              sx={styles.backButton}
              onClick={() => navigate(-1)}
            >
              {ButtonCaption.back}
            </Button>
        </Grid>
        <Grid item xs={9}>
          <Button
              color="primary"
              variant="contained"
              sx={styles.button}
              onClick={handleSubmit(onSubmit)}
            >
              {registeredId ? ButtonCaption.create : ButtonCaption.register}
            </Button>
        </Grid>
      </Grid>

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
}
export default FacilityNewAdmin