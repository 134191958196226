import { useEffect, useState } from "react"
import {
  Box,
  Button,
  Stack,
  Grid,
  TextField,
  Typography,
  Link,
  AlertColor,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LogInFormData, LogInSchema } from './schema/LogInSchema';

import { Snackbar } from './parts/Snackbar';
import { ButtonCaption, Sex } from "../utils/Constants";

import { styles } from './style/commonStyles'
import LayoutWithMenu from './LayoutWithMenu';
import { HeadlineWidhLogo } from './parts/HeadlineWidhLogo';

import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { useAuthUserContext } from '../contexts/AuthUserContext'

const LogIn = () => {
  const navigate = useNavigate();
  const params = useParams();

  const searchParams = new URLSearchParams(window.location.search)
  const defaultRedirectUrl = '/top'
  const redirectTo = searchParams.get('prev_url') || defaultRedirectUrl
  const defaultEmail = decodeURIComponent(searchParams.get('email') || '')
  const accountConfirmationSuccess = searchParams.get('account_confirmation_success') || ''

  const authUser = useAuthUserContext().authUser
  const signIn = useAuthUserContext().signIn

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  useEffect(() => {
    if (authUser) {
      navigate(defaultRedirectUrl)
    }
  }, []);

  const onSubmit: SubmitHandler<LogInFormData> = (data) => {
    const email = data.email
    const password = data.password
    const otp = data.otp
    const params: LogInFormData = {email, password, otp}

    if (!authUser) {
      signIn(
        params,
        () => {
          // setSnackbarProps('success', 'ログインしました', true);
          navigate(redirectTo)
        },
        () => {
          setSnackbarProps('warning', 'ログイン出来ませんでした。メールアドレス、パスワードをご確認ください', true);
        },
      )
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LogInFormData>({
    defaultValues: {
      email: defaultEmail
    },
    resolver: yupResolver(LogInSchema),
  })

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }
  
  return (
    <LayoutWithMenu
      hideNavLinks={true}
      navColor="white"
    >
      { accountConfirmationSuccess !== '' && (
        ( accountConfirmationSuccess === 'true' ) ?
        (<Typography  sx={{m: 3}} variant="subtitle2" align='center'>新規アカウント登録が完了いたしました。<br />下記のフォームからログインしてください。</Typography>) :
        (<>
          <Typography  sx={{m: 3}} variant="subtitle2" align='center'>アカウント登録完了までの有効期限が切れています。<br />お手数ですが、再度アカウントの新規登録手続きをお願いします。</Typography>
          <Link component={RouterLink} to="/signup" variant="body2">
              新規登録ページへ
          </Link>
        </>)
      )}

      { accountConfirmationSuccess !== 'false' && <>
        <HeadlineWidhLogo pageTitleName="ログイン" />
        <Stack spacing={7} >
          <Stack spacing={4} sx={{pt: 3}}>
            <TextField
              required
              label="メールアドレス"
              InputLabelProps={{ shrink: true }}
              {...register('email')}
              error={"email" in errors}
              helperText={errors.email?.message}
              placeholder='メールアドレスを入力してください'
              sx={styles.TextField}
            />
            <TextField
              required
              label="パスワード"
              type="password"
              InputLabelProps={{ shrink: true }}
              {...register('password')}
              error={"password" in errors}
              helperText={errors.password?.message}
              placeholder='パスワードを入力してください'
              sx={styles.TextField}
            />
          </Stack>

          <Stack spacing={2}>
            <Grid container alignItems='center' justifyContent='center' direction="column">
              <Grid item xs={2}>
                <Button
                  sx={styles.buttonSingle}
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  {ButtonCaption.signin}
                </Button>
              </Grid>
            </Grid>
            <Grid container alignItems='center' justifyContent='center' direction="column">
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Box>
                    <Link component={RouterLink} to="/password_reset" variant="caption">
                      パスワードをお忘れですか？
                    </Link>
                  </Box>
                  <Box>
                    <Link component={RouterLink} to="/signup" variant="caption">
                      アカウントを新規作成する
                    </Link>
                  </Box>
                </Stack>
              </Grid>
            </Grid>

          </Stack>
        </Stack>
      </>}
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
}
export default LogIn
