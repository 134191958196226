import React, { useState } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Cancel'
import { FormHelperText, Box, Stack } from "@mui/material";

import { icons } from "../../icon";
import ImageModal from './ImageModal';
import { SxProps, Theme } from '@mui/material';

type Props = {
  images: File[],
  setImages: (arg: File[]) => void,
  maxImagesUpload?: number,
  isSingleSelect?: boolean,
  isCancelButtonHide?: boolean,
  errorMessage?: string,
  imagesURL?: string[],
  setImagesURL?: (arg: string[]) => void,
  sx?: SxProps<Theme>;
  index?: number;
  onDeleteFile?: (arg: string) => void;
  onDeleteFile2?: (arg: string, index: number) => void;
  accept?: string;
  buttonText?: string;
}

// style定義
const styles = {
  deleteIcon: {
    position: 'absolute',
    top: -18,
    right: -18,
  },
  previewImageGrid: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    position: 'relative',
  }
}

const FileUploader = (props: Props) => {
  const maxImagesUpload = props.maxImagesUpload || 10
  const inputId = Math.random().toString(32).substring(2)

  const [errorMessage, setErrorMessage] = useState<string>(props.errorMessage || '')
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalImgSrc, setModalImgSrc] = useState<string>('')

  // 画像変更
  const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.isSingleSelect) {
      await handleOnReplaceImage(e)
      return
    }
    await handleOnAddImage(e)
  }

  // 許可するファイルの定義
  const acceptFileType = (fileType: string) => {
    return "image/png,image/jpg,image/jpeg,image/gif,application/pdf".split(',').includes(fileType)
  }

  // 画像プレビュー置き換え
  const handleOnReplaceImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    if (!e.target.files) return
    if (!acceptFileType(e.target.files[0].type)) {
      setErrorMessage('画像ファイル以外は登録できません')
      return
    }
    props.setImages([e.target.files[0]]) // 複数選択を許可しないため、必ず1件
    e.target.value = ''
  }

  // 画像プレビュー追加（複数選択許可時）
  const handleOnAddImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const files: File[] = []
    for (const file of e.target.files) {
      files.push(file)
    }
    props.setImages([...props.images, ...files])
    e.target.value = ''
  }

  // 画像プレビュー削除
  const handleOnRemoveImage = (index: number) => {
    const newImages = [...props.images]
    newImages.splice(index, 1)
    props.setImages(newImages)
  }

  // Modal表示内容セット
  const setModalContent = (url: string) => {
    setModalImgSrc(url)
    setModalOpen(true)
  }

  // 「画像・PDFアップロード」ボタンコンポーネント
  const uploadButtonComponent = () => {
    return(
      <Box display={'flex'} justifyContent={'center'}>
        <label htmlFor={inputId}>
          <Button
            color={'success'}
            variant='contained'
            size="small"
            disabled={props.images.length >= maxImagesUpload}
            component='span'
            startIcon={<icons.PlusIcon style={{ width: "10px", height: "10px" }} />} // ボタンの左端にアイコンを追加
            sx={{ mt: 1, fontSize: '10px'}}>
            {props.buttonText || '画像・PDFアップロード'}
          </Button>
          { errorMessage !== '' && <FormHelperText error={true}>{errorMessage}</FormHelperText> }
          <input
            id={inputId}
            type='file'
            multiple={!props.isSingleSelect}
            accept={props.accept || 'image/*,.png,.jpg,.jpeg,.gif,.pdf'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeImage(e)}
            style={{ display: 'none' }}
          />
        </label>
      </Box>
    )
  }

  // 画像プレビュー用コンポーネント
  const previewImageComponent = () => {
    return(
      props.images.map((image, i) => (
            <Box position="relative">
              {/* 画像プレビュー削除の「×」ボタン */}
              {!props.isCancelButtonHide &&
              <IconButton
                aria-label='delete image'
                sx={{ ...styles.deleteIcon, color: "#B3CAD4" }}
                onClick={() => handleOnRemoveImage(i)}
              >
                <CancelIcon fontSize="small" sx={{background: "#FFFFFF", borderRadius: "10px"}} />
              </IconButton>
            }
              <Box maxWidth="150px" maxHeight="150px">
                {image.type === 'application/pdf' ? (
                  <object
                    data={URL.createObjectURL(image)}
                    aria-label={image.name}
                    type="application/pdf"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                    }}
                  />
                ) : (
                  <img
                    alt={image.name}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      aspectRatio: '1 / 1'
                    }}
                    src={URL.createObjectURL(image)}/>
                )}
              </Box>
            </Box>
          ))
    )
  }

  // 登録画像表示用コンポーネント
  const displayRegisterImageComponent = () => {
    return(
      props.imagesURL && props.imagesURL.map((url, index) => {
          if (url !== '' && url !== null) {
            // URLからmedicine_notebookまたはhicard_imageのindex番号を抽出
            const match = url.match(/(medicine_notebook|hicard_image)(\d+)/);
            const number = match ? parseInt(match[2], 10) : null;

            return (
              <Box position="relative">
                {!props.isCancelButtonHide && (
                  <IconButton
                    aria-label="delete image"
                    sx={{ ...styles.deleteIcon, color: "#B3CAD4" }}
                    onClick={() => {
                      if (props.onDeleteFile) {
                        props.onDeleteFile(url);
                      } else if (props.onDeleteFile2 && number !== null) {
                        props.onDeleteFile2(url, number);
                      }
                    }}
                  >
                    <CancelIcon fontSize="small" sx={{background: "#FFFFFF", borderRadius: "10px"}} />
                  </IconButton>
                )}
                <Box maxWidth="150px" maxHeight="150px">
                  {url.endsWith(".pdf") ? (
                    <object
                      data={url}
                      aria-label={`お薬手帳${index}に登録されている画像`}
                      type="application/pdf"
                      style={{
                        
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        aspectRatio: "1 / 1",
                      }}
                      src={url}
                      alt={`お薬手帳${index}に登録されている画像`}
                      onClick={() => setModalContent(url)}
                    />
                  )}
                </Box>
              </Box>
            );
          }
          return null;
        })
    )
  }

  return (
    <Stack spacing={2}>
      <Box display="flex" flexDirection="column" alignItems="center">
        {/* 画像表示 */}
        {props.images.length === 0
          ? displayRegisterImageComponent()
          : previewImageComponent()}
        {/* 画像・PDFアップロードボタン */}
        {uploadButtonComponent()}
      </Box>

      <ImageModal
        open={modalOpen}
        setOpen={setModalOpen}
        imgSrc={modalImgSrc}
      />
    </Stack>
  );
}
export default FileUploader
