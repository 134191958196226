import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Button,
  AlertColor
} from "@mui/material";

import {
  getReservationWithFacility,
  createReceiptDetail,
  updateReceiptDetail
} from '../../lib/api/reservation';

import LayoutWithMenu from "../LayoutWithMenu";

import { styles as commoncStyles } from '../style/commonStyles';
import FileUploader from '../parts/FileUploader';
import { Snackbar } from '../parts/Snackbar';
import {ButtonCaption} from "../../utils/Constants";
import { randomFileName } from 'utils/helper';
import { deleteReceiptDetail } from '../../lib/api/reservation';
import { Reservation } from '../../interfaces/index';
import { formattedDatetime } from '../../utils/helper';


const commonButtonStyles = {
  minWidth: 120,
  width: 140,
  height: 35,
  boxShadow: "none",
  padding: "4px 16px",
  borderRadius: "3px",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "0.10000000149011612px",
  textAlign: "center",
  color: "#003642",
};

const styles = {
  ...commoncStyles,
  backButton: {
    ...commonButtonStyles,
    backgroundColor: "#FFFFFF",
    border: "1px solid #87D1EA",
    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
  },
  sendButton: {
    ...commonButtonStyles,
    backgroundColor: "#87D1EA",
    border: "1px solid #87D1EA",
    "&:hover": {
      backgroundColor: "#1aa3c9",
      boxShadow: "none",
    },
  }
}

// TODO: あとで共通化
class ReceiptDetailFormData {
  id: number = 0
  user_id: number = 0
  reservation_id: number = 0
  facility_id: number = 0
  receipt_files: File[] = []
}

const ReceiptDetailSending = () => {

  // 遷移パラメータ
  const navigation = useNavigate();
  const location = useLocation();
  const [params] = useState<{ reservation_id: number, user_id: number }>(location.state)

  const [reservation, setReservation] = useState<Reservation>();
  const [receiptDetail, setReceiptDetail] = useState<ReceiptDetailFormData>(new ReceiptDetailFormData());
  const [receiptFiles, setReceiptFiles] = useState<File[]>([]);
  const [receiptURLs, setReceiptURLs] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  useEffect(() => {
    if (params?.reservation_id) {
      displayData();
    }
  }, [params?.reservation_id]);

  //　初期データ表示内容取得＆設定
  const displayData = async () => {
    await getReceiptDetailData(params.reservation_id);
  }

// 診療明細情報取得
const getReceiptDetailData = async (reservationId: number) => {
  try {
    const result = await getReservationWithFacility(reservationId.toString());
    if (result.data) {
      setReservation(result.data);
      setReceiptDetail(result.data);
      const receiptFiles = result.data.receiptFiles;
      const receiptURLs = receiptFiles.map((file: any) => file.url);
      setReceiptURLs(receiptURLs);
    }
  } catch (error) {
    console.log(error);
    setSnackbarProps('error', '診療明細情報の取得に失敗しました', true);
  }
};

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }

// 診療明細情報登録実行
const sendReceiptDetail = async () => {
  try {
    const formData = new FormData();
    formData.append('reservation[user_id]', params.user_id.toString());
    formData.append('reservation[reservation_id]', params.reservation_id.toString());

    // 既存の診療明細ファイルのURLをFormDataに追加
    receiptURLs.forEach(url => formData.append('reservation[receipt_files][]', url));

    // 新しいファイルをFormDataに追加
    receiptFiles.map(file => {
      formData.append('reservation[receipt_files][]', file, randomFileName(file.name));
    });

    // 診療明細送信実行
    if (receiptDetail && receiptDetail.reservation_id) {
      await updateReceiptDetail(receiptDetail.reservation_id, formData);
    } else {
      await createReceiptDetail(formData);
    }

    // 情報の再取得
    await getReceiptDetailData(params.reservation_id);
    setSnackbarProps('success', '診療明細が正常に送信されました', true);
  } catch (error) {
    console.log(error);
    setSnackbarProps('error', '診療明細の送信に失敗しました', true);
  }
};

// 診療明細削除
const handleDeleteFile = async (fileName: string) => {
  try {
    await deleteReceiptDetail(receiptDetail.id, fileName);
    // 削除に成功したら、ファイルリストから削除
    const updatedURLs = receiptURLs.filter(url => !url.includes(fileName));
    setReceiptURLs(updatedURLs);
    setSnackbarProps('success', 'ファイルが削除されました', true);
  } catch (error) {
    console.error(error);
    setSnackbarProps('error', 'ファイルの削除に失敗しました', true);
  }
}

  return (
    <LayoutWithMenu pageTitle='診療明細送信（患者さん宛）'>
      {reservation && (
        <>
          <p>予約日時: {formattedDatetime(reservation.reservationTime)}</p>
          <p>{reservation.facility.name}</p>
        </>
      )}

        <FileUploader
          images={receiptFiles}
          setImages={setReceiptFiles}
          isSingleSelect={false}
          imagesURL={receiptURLs}
          setImagesURL={setReceiptURLs}
          onDeleteFile={handleDeleteFile}
        />
          <Grid container pt={3} justifyContent="center" spacing={2}>
          <Grid item>
          <Button
          sx={styles.backButton}
          color="secondary"
          variant="outlined"
          onClick={() => navigation(-1)}
        >
          {ButtonCaption.back}
        </Button>
      </Grid>
      <Grid item>
        <Button
          sx={styles.sendButton}
          color="primary"
          variant="contained"
          disabled={receiptFiles.length === 0}
          onClick={() => sendReceiptDetail()}
        >
          診療明細送信
        </Button>
          </Grid>
        </Grid>

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  )
}
export default ReceiptDetailSending