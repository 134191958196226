import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

type Props = {
  isOpen: boolean,
  content: string,
  isHiddenCancel?: boolean,
  confirmButtonLabel?: string,
  cancelButtonLabel?: string,
  onConfirm: () => void,
  onCancel?: () => void,
}

const CustomDialog = (props: Props) => {
  return (
    <React.Fragment>
      {/* Dialogコンポーネントの利用 */}
      <Dialog
        open={props.isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* DialogContentコンポーネントの利用 */}
        <DialogContent>
          {/* DialogContentTextコンポーネントの利用 */}
          <DialogContentText
            id="alert-dialog-description"
            sx={{ whiteSpace: 'pre-line' }}  // ダイアログのテキストスタイルのカスタマイズ
          >
            {props.content}
          </DialogContentText>
        </DialogContent>

        {/* DialogActionsコンポーネントの利用 */}
        <DialogActions>
          {/* Buttonコンポーネントの利用 */}
          <Button onClick={props.onConfirm}>
            {props.confirmButtonLabel || 'OK'}
          </Button>
          {/* Buttonコンポーネントの利用 */}
          { !props.isHiddenCancel &&
            <Button onClick={props.onCancel}>
              {props.cancelButtonLabel || 'キャンセル'}
            </Button>
          }
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default CustomDialog