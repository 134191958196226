import { useEffect, useState } from "react"
import {
  Button,
  Stack,
  Grid,
  TextField,
  Typography,
  Link,
  AlertColor,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LogInFormData, LogInSchema } from '../schema/LogInSchema';

import { Snackbar } from '../parts/Snackbar';
import { ButtonCaption, Sex } from "../../utils/Constants";

import LayoutWithMenu from '../LayoutWithMenu';

import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { useAuthAdminUserContext } from '../../contexts/AuthAdminUserContext'

const AdminUserLogin = () => {
  const navigate = useNavigate();
  const params = useParams();

  const searchParams = new URLSearchParams(window.location.search)
  const defaultRedirectUrl = '/admin/user_list'
  const defaultEmail = searchParams.get('email') || ''
  const redirectTo = searchParams.get('prev_url') || defaultRedirectUrl
  const accountConfirmationSuccess = searchParams.get('account_confirmation_success') || ''

  const authAdminUser = useAuthAdminUserContext().authAdminUser
  const signIn = useAuthAdminUserContext().signIn

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [otpRequired, setOtpRequired] = useState<boolean>(false);

  useEffect(() => {
    if (authAdminUser) {
      navigate(defaultRedirectUrl)
    }
  }, []);

  const onSubmit: SubmitHandler<LogInFormData> = (data) => {
    const email = data.email
    const password = data.password
    const otp = data.otp
    const params: LogInFormData = {email, password, otp}

    if (!authAdminUser) {
      signIn(
        params,
        (response) => {
          if (response === 'require OTP') {
            setOtpRequired(true);
            setSnackbarProps('success', 'ワンタイムパスワードをメール送信しました', true);
          } else {
            setSnackbarProps('success', '管理者ログインしました', true);
            navigate(redirectTo)
          }
        },
        () => {
          setSnackbarProps('warning', 'ログイン出来ませんでした。メールアドレス、パスワードをご確認ください', true);
        },
      )
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LogInFormData>({
    defaultValues: {
      email: defaultEmail
    },
    resolver: yupResolver(LogInSchema),
  })

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }
  
  return (
    <LayoutWithMenu
      pageTitle={'管理者ログイン'}
      hideNavLinks={true}
      navColor="white"
    >
      { accountConfirmationSuccess !== '' && (
        ( accountConfirmationSuccess === 'true' ) ?
        (<Typography  sx={{m: 3}} variant="subtitle2" align='center'>新規アカウント登録が完了いたしました。<br />下記のフォームからログインしてください。</Typography>) :
        (<>
          <Typography  sx={{m: 3}} variant="subtitle2" align='center'>アカウント登録完了までの有効期限が切れています。<br />お手数ですが、再度アカウントの新規登録手続きをお願いします。</Typography>
          <Link component={RouterLink} to="/signup" variant="body2">
              新規登録ページへ
          </Link>
        </>)
      )}

      { accountConfirmationSuccess !== 'false' && <>
        <Stack spacing={3} sx={{pt: 3}}>

          <TextField
            required
            label="メールアドレス"
            InputLabelProps={{ shrink: true }}
            {...register('email')}
            error={"email" in errors}
            helperText={errors.email?.message}
          />

          <TextField
            required
            label="パスワード"
            type="password"
            InputLabelProps={{ shrink: true }}
            {...register('password')}
            error={"password" in errors}
            helperText={errors.password?.message}
          />

          {otpRequired && (
            <TextField
              required
              label="ワンタイムパスワード"
              InputLabelProps={{ shrink: true }}
              {...register('otp')}
              error={"otp" in errors}
              helperText={errors.otp?.message}
            />
          )}

          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="次回から自動的にログインする"
          /> */}

          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSubmit(onSubmit)}
          >
            {ButtonCaption.signin}
          </Button>
        </Stack>
        <Grid container sx={{my: 2}} >
          <Grid item xs={6}>
            <Link component={RouterLink} to="/admin/password_reset" variant="caption">
              パスワードをお忘れですか？
            </Link>
          </Grid>
          <Grid item xs={6}>

          </Grid>
        </Grid>
      </>}
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
}
export default AdminUserLogin
