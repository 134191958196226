import yup from "../../utils/yup.locale";

// フォームの型
export class SignUpFormData {
  email: string = ''
  password: string = ''
  confirm_success_url?: string = ''
}

// バリデーションルール
export const SignUpSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(8, "英数小文字8文字以上で入力してください")
    .max(128)
    .matches(/^(?=.*[a-z])(?=.*\d)[a-z\d]+$/, '半角英数字を両方含めてください')
    .required(),
});
