import { useEffect, useState } from 'react'
import {
  Button,
  Grid,
  Stack,
  TextField,
  AlertColor,
  Typography,
} from '@mui/material';
import Cookies from "js-cookie"
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordResetFormData, PasswordResetSchema } from './schema/PasswordResetSchema';

import client from '../lib/api/client';
import { Snackbar } from './parts/Snackbar';
import { ButtonCaption } from "../utils/Constants";

import { styles } from './style/commonStyles'
import LayoutWithMenu from './LayoutWithMenu';

import { useAuthUserContext } from '../contexts/AuthUserContext'

const UserEmailEdit = () => {
  const authUser = useAuthUserContext().authUser
  const navigate = useNavigate();

  const [userId, setUserId] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  const [data, setData] = useState<PasswordResetFormData>(new PasswordResetFormData());

  useEffect(() => {
    (async() => {
      const userId = authUser?.id.toString() || ''
      const response = await initDisplayData(userId);
      setUserId(userId)
      setData(response)
    })()
  },[])

  useEffect(() => {
    reset(data)
  }, [data])

  // 初期表示データ取得
  const initDisplayData = async (userId: string) => {
    try {
      const result = await client.get(`users/${userId}`);
      const data = result.data;
      return {
        email: data.email,
      } as PasswordResetFormData;
    } catch (error) {
      setSnackbarProps('error', 'ユーザー情報の取得に失敗しました', true);
      // console.log(error);
      return new PasswordResetFormData();
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(PasswordResetSchema),
    defaultValues: {
      email: data.email
    }
  })

  // フォーム送信時の処理
  const onSubmit: SubmitHandler<PasswordResetFormData> = async (data) => {
    // ユーザー情報更新
    try {
      await client.put(`users/${userId}`, data);
      setSnackbarProps('success', 'ユーザー情報が更新されました', true);
      Cookies.set("_u_uid", data.email)
    } catch (error) {
      setSnackbarProps('error', 'ユーザー情報の更新に失敗しました', true);
    }
  }

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }

  return (
    <LayoutWithMenu>

      <Stack spacing={5} sx={{mt: 10}}>
        <Typography sx={{mt: 4, pb: 2, fontSize:24, fontWeight: 'bold'}} align='center'>メールアドレス変更</Typography>
      </Stack>

      <Stack spacing={6} sx={{pt: 3}}>
        <TextField
          required
          label="メールアドレス"
          InputLabelProps={{ shrink: true }}
          {...register('email')}
          error={"email" in errors}
          helperText={errors.email?.message}
          placeholder='メールアドレスを入力してください'
          sx={styles.TextField}
          />
        <Grid container alignItems='center' justifyContent="space-around" direction="row">
          <Grid item xs={5}>
            <Button
              sx={styles.buttonOutlined}
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {ButtonCaption.back}
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              sx={styles.buttonSimple} 
              onClick={handleSubmit(onSubmit)}
            >
              {ButtonCaption.edit}
            </Button>
          </Grid>
        </Grid>
      </Stack>

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  )
}
export default UserEmailEdit