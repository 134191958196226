import React, { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Link,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { getUserList } from '../../lib/api/user'; 

import { UserFormData } from '../schema/UserSchema';
import LayoutWithMenu from '../LayoutWithMenu';
import { ButtonCaption } from "../../utils/Constants";

const styles = {
  noteMobile: {
    pb: 1,
    fontSize: 12,
    display: { xs: 'block', sm: 'none' }
  },
  notePC: {
    pb: 1,
    fontSize: 12,
    display: { xs: 'none', sm: 'block' }
  },
  GridContainer: {
    borderBottom: 1,
    borderColor: 'lightGrey',
    py: 1
  },
  GridItem: {
    alignContent: 'center',
  },
  LinkStyle: {
    cursor: 'pointer'
  },
  ColumnLabel: {
    fontSize: 12,
    marginTop: 2,
    marginLeft: 6,
  }
}

const UserListAdmin = () => {

  const [users, setUsers] = useState<Array<UserFormData>>([]);
  
  const navigation = useNavigate();

  // 初期表示処理
  useEffect(() => {
    (async() => {
      await getUserList()
      .then(response => {
        // console.log(response)
        setUsers(response.data);
      })
      .catch(error => {
        console.error("医療機関一覧の取得に失敗しました", error);
        // エラー処理
      });
    })();
  },[]) 

  // 詳細画面遷移
  const moveToDetail = (data: UserFormData) => {
    navigation(`/admin/user/?user_id=${data.id}`);
  }

  return (
    <LayoutWithMenu pageTitle={'患者一覧'}>
      {/* <Typography sx={styles.noteMobile}>※ 医療機関名をタップすると詳細情報を表示します</Typography>
      <Typography sx={styles.notePC}>※ 医療機関名をクリックすると詳細情報を表示します</Typography> */}
      {users.map((data, index) => {
        return (
          <Grid container key={index} sx={styles.GridContainer}>
            <Grid container item xs={3} sx={styles.GridItem}>
              <Link sx={styles.LinkStyle} onClick={ () => moveToDetail(data) }>
                {data.name} 
              </Link>
            </Grid>
            <Grid container item xs={1} sx={styles.GridItem}>
              <span style={styles.ColumnLabel}>
                {data.sex}
              </span>
            </Grid>
            <Grid container item xs={2} sx={styles.GridItem}>
              <span style={styles.ColumnLabel}>
                {data.birth}
              </span>
            </Grid>
            <Grid container item xs={4} sx={styles.GridItem}>
              <span style={styles.ColumnLabel}>
                {data.email}
              </span>
            </Grid>
            <Grid container item xs={2} sx={styles.GridItem}>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                onClick={ () => moveToDetail(data) }
              >
                {ButtonCaption.show}
              </Button>
            </Grid>
          </Grid>
        )
      })}
    </LayoutWithMenu>
  )
}
export default UserListAdmin