import client from "lib/api/client"
import { FacilityNewFormData } from '../../components/schema/FacilitySchema';

// クリニック一覧取得
export const getClinicList = () => {
  return client.get(`clinic`)
}

// クリニック詳細取得
export const getClinicDetail = (id: string) => {
  return client.get(`clinic/${id}`)
}

// クリニック新規作成
export const newClinic = (params: FacilityNewFormData) => {
  return client.post(`clinic`, params)
}

