import { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import { AuthUserProvider } from 'contexts/AuthUserContext'
import { AuthFacilityStaffProvider } from 'contexts/AuthFacilityStaffContext'
import { AuthAdminUserProvider } from 'contexts/AuthAdminUserContext'

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './components/style/theme';

import IndicatorData from './lib/IndicatorData';
import Indicator from 'components/parts/Indicator';
import AuthenticatedUser from './components/auth/AuthenticatedUser'
import AuthenticatedFacilityStaff from './components/auth/AuthenticatedFacilityStaff'
import AuthenticatedAdminUser from './components/auth/AuthenticatedAdminUser'
import LogIn from './components/LogIn'
import SignUp from './components/SignUp'
import SignOut from './components/SignOut'
import PasswordReset from './components/PasswordReset'
import PasswordUpdate from './components/PasswordUpdate'
import Test from './components/Test'
import NotFound from './components/NotFound'
import UserEdit from './components/UserEdit';
import UserEmailEdit from './components/UserEmailEdit';
import UserPasswordEdit from './components/UserPasswordEdit';
import UserDetail from './components/UserDetail';
import UserDetailAdmin from './components/admin/UserDetailAdmin';
import UserListAdmin from './components/admin/UserListAdmin';
import MedicalInstitutionList from './components/MedicalInstitutionList';
import MedicalInstitutionDetail from './components/MedicalInstitutionDetail';
import FacilityStaffLogin from './components/facility/FacilityStaffLogIn'
import FacilityStaffPasswordReset from './components/facility/FacilityStaffPasswordReset'
import FacilityStaffPasswordUpdate from './components/facility/FacilityStaffPasswordUpdate'
import FacilityReservationList from './components/facility/FacilityReservation'
import ReceiptDetailSending from './components/facility/ReceiptDetailSending'
import PrescriptionSending from './components/facility/PrescriptionSending'
import PrescriptionList from './components/facility/PrescriptionList'
import ClinicReservationDetail from './components/facility/ClinicReservationDetail'
import PharmacyReservationDetail from './components/facility/PharmacyReservationDetail'
import FacilityEdit from './components/facility/FacilityEdit'
import FacilityDetail from './components/facility/FacilityDetail'
import FacilitySignOut from './components/facility/FacilitySignOut'
import HicardList from "./components/facility/HicardList";
import MedicinebookList from "./components/facility/MedicinebookList";
import InsuranceCard from './components/InsuranceCard';
import MedicineNotebook from 'components/MedicineNotebook';
import Billing from './components/Billing';
import AdminUserLogin from './components/admin/AdminUserLogIn'
import AdminUserPasswordReset from './components/admin/AdminUserPasswordReset'
import AdminUserPasswordUpdate from './components/admin/AdminUserPasswordUpdate'
import AdminTest from './components/admin/AdminTest'
import AdminMedicalInstitutionList from './components/admin/AdminMedicalInstitutionList'
import AdminPharmacyList from './components/admin/AdminPharmacyList'
import FacilityDetailAdmin from './components/admin/FacilityDetailAdmin'
import FacilityEditAdmin from './components/admin/FacilityEditAdmin'
import FacilityNewAdmin from './components/admin/FacilityNewAdmin'
import ClinicStaffNew from './components/admin/ClinicStaffNew'
import ClinicStaffEdit from './components/admin/ClinicStaffEdit'
import PharmacyStaffNew from './components/admin/PharmacyStaffNew'
import PharmacyStaffEdit from './components/admin/PharmacyStaffEdit'
import AdminSignOut from './components/admin/AdminSignOut'
import ReservationList from "./components/ReservationList";
import ReservationDetail from "./components/ReservationDetail";
import PrescriptionEdit from "./components/PrescriptionEdit";
import PharmacyReservation from "./components/PharmacyReservation";
import PharmacyList from "./components/PharmacyList";
import PharmacyDetail from "./components/PharmacyDetail";
import ReceiptDetailList from './components/ReceiptDetailList';
import ReceiptDetail from './components/ReceiptDetail';
import PrescriptionRegister from './components/PrescriptionRegister';
import FacilityCommerce from './components/FacilityCommerce';
import Top from "./components/Top";
import ReservedUserMedicineNotebook from 'components/facility/ReservedUserMedicineNotebook';
import ReservedUserHicard from 'components/facility/ReservedUserHicard';

const App: React.FC = () => {
  const [indicator, setIndicator] = useState({ isOpen: false });
  IndicatorData.setIndicator = setIndicator;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthAdminUserProvider>
        <AuthFacilityStaffProvider>
          <AuthUserProvider>
            <Routes>
              <Route path='/login' element={<LogIn />} />
              <Route path='/signup' element={<SignUp />} />
              <Route path='/password_reset' element={<PasswordReset />} />
              <Route path='/password_update' element={<PasswordUpdate />} />
              <Route path='/facility/login' element={<FacilityStaffLogin />} />
              <Route path='/facility/password_reset' element={<FacilityStaffPasswordReset />} />
              <Route path='/facility/password_update' element={<FacilityStaffPasswordUpdate />} />
              <Route path='/admin/login' element={<AdminUserLogin />} />
              <Route path='/admin/password_reset' element={<AdminUserPasswordReset />} />
              <Route path='/admin/password_update' element={<AdminUserPasswordUpdate />} />
              <Route path='*' element={<NotFound />} />
              <Route path='/facility' element={<AuthenticatedFacilityStaff />}>
                <Route index element={<Navigate to='reservation' />} />
                <Route path='reservation' element={<FacilityReservationList />} />
                <Route path='reservation/clinic/:reservation_id' element={<ClinicReservationDetail />} />
                <Route path='reservation/clinic/:reservation_id/medicine_notebook' element={<ReservedUserMedicineNotebook />} />
                <Route path='reservation/clinic/:reservation_id/hicard' element={<ReservedUserHicard />} />
                <Route path='reservation/pharmacy/:reservation_id' element={<PharmacyReservationDetail />} />
                <Route path='reservation/pharmacy/:reservation_id/medicine_notebook' element={<ReservedUserMedicineNotebook />} />
                <Route path='reservation/pharmacy/:reservation_id/hicard' element={<ReservedUserHicard />} />
                <Route path='reservation/receipt_detail/send' element={<ReceiptDetailSending />} />
                <Route path='reservation/prescription/send' element={<PrescriptionSending />} />
                <Route path='reservation/prescription/list' element={<PrescriptionList />} />
                <Route path='edit' element={<FacilityEdit />} />
                <Route path='detail' element={<FacilityDetail />} />
                <Route path='signout' element={<FacilitySignOut />} />
                <Route path='hicard' element={<HicardList />}/>
                <Route path='medicinebook' element={<MedicinebookList />}/>
                <Route path='billing' element={<Billing facilityType='pharmacy' reservationId={1}/>} />
              </Route>
              <Route path='/' element={<AuthenticatedUser />}>
                <Route path='/top' element={<Top />} />
                <Route path='/signout' element={<SignOut />} />
                <Route index element={<Navigate to='top' />} />
                <Route path='/user/edit' element={<UserEdit />} />
                <Route path='/user' element={<UserDetail />} />
                <Route path='/user/email/edit' element={<UserEmailEdit />} />
                <Route path='/user/password/edit' element={<UserPasswordEdit />} />
                <Route path='/medical_institution' element={<MedicalInstitutionList />} />
                <Route path='/medical_institution/detail/:id' element={<MedicalInstitutionDetail />} />
                <Route path='/medical_institution/:facility_id/commerce' element={<FacilityCommerce />} />
                <Route path='/pharmacy' element={<PharmacyList />} />
                <Route path='/pharmacy/detail/:id' element={<PharmacyDetail />} />
                <Route path='/pharmacy/:facility_id/commerce' element={<FacilityCommerce />} />
                <Route path='/pharmacy/reservation' element={<PharmacyReservation />}/>
                <Route path='/insurance_card' element={<InsuranceCard />} />
                <Route path='/medicine_notebook' element={<MedicineNotebook />} />
                <Route path='/reservation' element={<ReservationList />}/>
                <Route path='/reservation/:reservation_id' element={<ReservationDetail />} />
                <Route path='/reservation/:reservation_id/prescription' element={<PrescriptionEdit />} />
                <Route path='/receipt_detail' element={<ReceiptDetailList />} />
                <Route path='/receipt_detail/:reservation_id' element={<ReceiptDetail />} />
                <Route path='/prescription/register' element={<PrescriptionRegister />}/>
              </Route>
              <Route path='/admin' element={<AuthenticatedAdminUser/>}>
                <Route index element={<Navigate to='user_list' />} />
                <Route path='admin_test' element={<AdminTest />} />
                <Route path='clinic/list' element={<AdminMedicalInstitutionList />} />
                <Route path='pharmacy/list' element={<AdminPharmacyList />} />
                <Route path='facility/detail' element={<FacilityDetailAdmin />} />
                <Route path='facility/edit' element={<FacilityEditAdmin />} />
                <Route path='facility/new' element={<FacilityNewAdmin />} />
                <Route path='clinic_staff/new' element={<ClinicStaffNew />} />
                <Route path='clinic_staff/edit' element={<ClinicStaffEdit />} />
                <Route path='pharmacy_staff/new' element={<PharmacyStaffNew />} />
                <Route path='pharmacy_staff/edit' element={<PharmacyStaffEdit />} />
                <Route path='user' element={<UserDetailAdmin />} />
                <Route path='user_list' element={<UserListAdmin />} />
                <Route path='signout' element={<AdminSignOut />} />
              </Route>
            </Routes>
            <div>
              <Indicator open={ indicator.isOpen } />
            </div>
          </AuthUserProvider>
        </AuthFacilityStaffProvider>
      </AuthAdminUserProvider>
    </ThemeProvider>
  )
}

export default App
