import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  AppBar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  Toolbar,
  IconButton,
  Button,
  Breakpoint,
} from '@mui/material';

import { useNavigate, Link as RouterLink } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { useAuthUserContext } from '../contexts/AuthUserContext'
import { useAuthFacilityStaffContext } from 'contexts/AuthFacilityStaffContext';
import { useAuthAdminUserContext } from 'contexts/AuthAdminUserContext';

import { styles } from './style/commonStyles'

type LayoutProps = {
  pageTitle?: string,
  hideNavLinks?: boolean,
  children: React.ReactNode,
  navColor?: string,
  // メイン領域の最大表示幅。デフォルトは sm
  mainContentsMaxWidth?: Breakpoint,
}

type MenuItemProps = {
  text: string,
  url: string,
  target?: string,
  rel?: string,
}

type MenuProps = {
  center: MenuItemProps[],
  right: MenuItemProps[],
}

// 一般利用者用メニュー
const UserMenu: MenuProps = {
  center: [
    { text: "トップ", url: "/top" },
    { text: "診療予約", url: "/medical_institution" },
    { text: "薬局予約", url: "/pharmacy" },
    { text: "基本情報", url: "/user" },
    { text: "メールアドレス", url: "/user/email/edit" },
    { text: "パスワード", url: "/user/password/edit" },
    { text: "お薬手帳", url: "/medicine_notebook" },
    { text: "保険証登録", url: "/insurance_card" },
    { text: "ログアウト", url: "/signout"},
  ],
  right: [
    { text: "お問い合わせ", url: "https://forms.gle/DpqisDQDR6RzRede6", target: "_blank", rel: "noopener noreferrer"}
  ]
};

// 施設用メニュー
const FacilityMenu: MenuProps = {
  center: [
    { text: "予約リスト", url: "/facility/reservation" },
    { text: "マイページ", url: "/facility/detail" },
    { text: "予約システム", url: "https://manage-bizcare.resv.jp/", target: "_blank", rel: "noopener noreferrer"},
    { text: "決済システム", url: "https://squareup.com/login?lang_code=ja-jp", target: "_blank", rel: "noopener noreferrer"},
    { text: "ログアウト", url: "/facility/signout"},
  ],
  right: []
};

// クリニック用メニュー
const ClinicMenu: MenuProps = {
  center: [
    { text: "予約リスト", url: "/facility/reservation" },
    { text: "マイページ", url: "/facility/detail" },
    { text: "予約システム", url: "https://manage-bizcare.resv.jp/", target: "_blank", rel: "noopener noreferrer"},
    { text: "決済システム", url: "https://squareup.com/login?lang_code=ja-jp", target: "_blank", rel: "noopener noreferrer"},
    { text: "ログアウト", url: "/facility/signout"},
  ],
  right: []
};

// 医療機関のadminメニュー
const AdminMenu: MenuProps = {
  center: [
    { text: "医療機関一覧", url: "/admin/clinic/list" },
    { text: "医療機関作成", url: "/admin/facility/new?facility_type=clinic" },
    { text: "医療機関スタッフ作成", url: "/admin/clinic_staff/new" },
    { text: "薬局一覧", url: "/admin/pharmacy/list" },
    { text: "薬局作成", url: "/admin/facility/new?facility_type=pharmacy" },
    { text: "薬局スタッフ作成", url: "/admin/pharmacy_staff/new" },
    { text: "患者一覧", url: "/admin/user_list" },
    { text: "ログアウト", url: "/admin/signout"},
  ],
  right: []
};

const LayoutWithMenu = (props: LayoutProps) => {

  // メニュー制御（内容・色）
  const [centerNavLinks, setCenterNavLinks] = useState<Array<{ text: string, url: string, target?: string, rel?: string}>>([]);
  const [rightNavLinks, setRightNavLinks] = useState<Array<{ text: string, url: string, target?: string, rel?: string}>>([]);
  const [navLinks, setNavLinks] = useState<Array<{ text: string, url: string, target?: string, rel?: string}>>([]);
  const [navColor, setNavColor] = useState<string>('');
  const authUser = useAuthUserContext().authUser;
  const authFacilityStaff = useAuthFacilityStaffContext().authFacilityStaff;
  const authAdminUser = useAuthAdminUserContext().authAdminUser;

  // メニュー開閉制御
  const [open, setOpen] = useState(false); 
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  // 画面遷移用
  const navigate = useNavigate();

  // 初期表示
  useEffect(() => {
    if (authUser) {
      setCenterNavLinks(UserMenu.center)
      setRightNavLinks(UserMenu.right)
      setNavLinks([...UserMenu.center, ...UserMenu.right]);
      setNavColor('#ffffff');
    }
    if (authFacilityStaff) {
      if (authFacilityStaff.facilityType === 'Clinic') {
        setCenterNavLinks(ClinicMenu.center)
        setRightNavLinks(ClinicMenu.right)
        setNavLinks([...ClinicMenu.center, ...ClinicMenu.right]);
      }
      if (authFacilityStaff.facilityType === 'Pharmacy') {
        setCenterNavLinks(FacilityMenu.center)
        setRightNavLinks(FacilityMenu.right)
        setNavLinks([...FacilityMenu.center, ...FacilityMenu.right]);
      }
      setNavColor('#ffffff');
    }
    if (authAdminUser) {
      setCenterNavLinks(AdminMenu.center)
      setRightNavLinks(AdminMenu.right)
      setNavLinks([...AdminMenu.center, ...AdminMenu.right]);
      setNavColor('#ffffff');
    }
    if (props.navColor) setNavColor(props.navColor)
  }, [])

  // モバイルメニュー用リンクボタン
  const mobileMenuButton = (item: MenuItemProps) => {
    if (item.target === '_blank') {
      return (
        <ListItemButton
          onClick={() => window.open(item.url, item.target)}
          sx={{ textAlign: 'center', borderBottom: "solid 1px #696969" }} >
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                color: '#171C1F',
                fontWeight: 'bold',
              }}
            />
        </ListItemButton>
      )
    }

    return (
      <ListItemButton
        onClick={() => navigate(item.url)}
        sx={{ textAlign: 'center', borderBottom: "solid 1px #696969" }}
      >
        <ListItemText
          primary={item.text}
          primaryTypographyProps={{
            color: '#171C1F',
            fontWeight: 'bold',
          }}
        />
      </ListItemButton>
    )
  }

  // モバイル用Drawerメニュー内容
  const drawer = (
    <Box onClick={handleDrawerClose} sx={{ textAlign: 'center' }}>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleDrawerClose} sx={{ textAlign: 'center', borderBottom: "solid 1px #696969" }}>
            <ListItemText primary={<CloseIcon />} />
          </ListItemButton>
        </ListItem>
        {navLinks.map((item) => (
            <ListItem 
              key={item.text}
              disablePadding
            >
              { mobileMenuButton(item) }
            </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {/* ヘッダー（メニュー） */}
      <AppBar component="nav" sx={{backgroundColor: navColor }}>
        <Toolbar disableGutters sx={{padding: '16px 64px 16px 24px'}}>
          {/* モバイル用ハンバーガーメニュー */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ color: 'black',  mr: 2, display: props.hideNavLinks ? 'none' : { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          {/* PC用メニュー */}
          <Box
            component="div"
            sx={{ display: { xs: 'none', sm: 'block', color: 'black' }, marginLeft: '40px' }}
          >
            <img src='/images/logo@2x.png' alt='Bizcare' style={{width: '119px', height: '48px'}}/>
          </Box>
          <Box sx={{ flexGrow: 1, display: props.hideNavLinks ? 'none' : { xs: 'none', sm: 'block' }, padding: '0 10px', textAlign: 'right' }}>
            {centerNavLinks.map((item) => (
              <Button
                key={item.text}
                variant="text"
                size="medium"
                sx={styles.buttonBoldTextNoHover}
                component={RouterLink}
                to={item.url}
                target={item.target}
                rel={item.rel}
              >
                {item.text}
              </Button>
            ))}
          </Box>
          { rightNavLinks.length > 0 &&
            <Box sx={{ display: props.hideNavLinks ? 'none' : { xs: 'none', sm: 'block' }, minWidth: "9em" }}>
              {rightNavLinks.map((item) => (
                <Button key={item.text} sx={styles.buttonSimple}>
                  {item.text}
                </Button>
              ))}
            </Box>
          }
        </Toolbar>
        {/* </Container> */}
      </AppBar>

      <nav>
        <Drawer
          anchor='top'
          variant="temporary"
          open={open}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      {/* メインコンテンツ */}
      <Container maxWidth={props.mainContentsMaxWidth ?? "sm"} sx={{ pt: 10, fontSize: 13 }}>
        {/* pageTitleが設定している場合のみタイトルを表示 */}
        {props.pageTitle && props.pageTitle !== '' &&
          <Typography sx={styles.TypographyPageTitle}>{props.pageTitle}</Typography>
        }
        {props.children}
      </Container>

      {/* フッター */}
      <span style={{margin: '10px'}} />
    </>
  )
}
export default LayoutWithMenu