import yup from "../../utils/yup.locale";

// フォームの型
export class BillingData {
  copaymentAmount: number = 0
  shippingFee: number = 0
  handlingFee: number = 0
  totalFee: number = 0
}

// バリデーションルール
export const BillingSchema = yup.object({
  copaymentAmount: yup
    .number()
    .required(),
  shippingFee: yup
    .number()
    .required(),
  handlingFee: yup
    .number()
    .required(),
  totalFee: yup
    .number()
    .required(),
});
