import LayoutWithMenu from "./LayoutWithMenu";
import { Reservation, PharmacyReservationRelation, ClinicReservationRelation } from "../interfaces";
import React, {useEffect, useState} from "react";
import {useAuthUserContext} from "../contexts/AuthUserContext";
import {Button, Grid, Box, Container, FormHelperText, Typography} from "@mui/material";
import { styles as commonStyles } from './style/commonStyles';
import {ButtonCaption, FacilityType, ReservationStatus} from "../utils/Constants";
import {formattedDatetime} from "../utils/helper";
import {useNavigate, useParams, Link} from "react-router-dom";
import {getReservationWithFacility, getPharmacyReservationRelation, getClinicReservationRelation} from "../lib/api/reservation";
import { getPaymentByReservation } from "../lib/api/payment";
import Chip, {ChipType} from "./parts/Chip";

import { icons } from "../icon";
import {
  LocalHospital as HospitalIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
  AccessTime as ClockIcon,
  Info as InfoIcon,
  Note as NoteIcon,
  Medication as MedicinesIcon,
  Comment as CommentIcon,
} from "@mui/icons-material";


const customStyles = {
  ...commonStyles,
  gridContainer: {
    borderBottom: "1px solid lightGrey",
    fontSize: 14,
    textDecoration: "none",
    color: "#000000",
    py: 1,
    backgroundColor: "#F5FAFD !important",
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "center", // 行の高さを揃えるために追加
    marginTop: 0,
    marginLeft: "0 !important",
  },
  gridItem: {
    alignContent: "center",
    backgroundColor: "#F5FAFD !important",
    display: "flex", // アイテムを中央揃えにするために追加
    alignItems: "center", // アイテムを中央揃えにするために追加
    paddingLeft: "0 !important",
  },
  boldLabel: {
    fontWeight: 600, // 項目名を太字にする
  },
  container: {
    width: "100%",
    paddingLeft: "24px",
    paddingRight: "24px",
    textAlign: "center !important",
  },
  icon: {
    width: "20px",
    height: "20px",
    marginRight: "0px",
    color: "#32AFDA",
    paddingLeft: "5px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10%", // ボタン間の間隔を設定
    width: "100%",
    marginTop: "30px",
  },
  Box: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "16px",
    marginBottom: "16px",
  },
  ButtonContainer: {
    display: "grid",
    justifyContent: "center",
    marginTop: "16px",
  },
  messageContainer: {
    display: "grid",
    justifyContent: "center",
  },
};

// 各行に表示するアイコンのリストを作成
const iconsList = [
  MedicinesIcon,
  HospitalIcon,
  PhoneIcon,
  LocationIcon,
  ClockIcon,
  InfoIcon,
  NoteIcon,
  CommentIcon
];

type RelateReservation = {
  reservationId: number,
  reservationTime: Date,
  facilityName: string,
  facilityAddress: string,
  tel: string,
  fax: string,
  facilityType: string,
}

type PaymentLimitedType = {
  id: number,
  amount: number,
  status: string,
  paymentLink: string,
}

const ReservationDetail = () => {
  const [reservation, setReservation] = useState<Reservation>();
  const [relationReservations, setRelationReservations] = useState<RelateReservation[]>([]);
  const [payment, setPayment] = useState<PaymentLimitedType>();
  const authUser = useAuthUserContext().authUser;
  const navigation = useNavigate();
  const { reservation_id } = useParams<{ reservation_id: string }>();

  const [hasReceiptDetail, setHasReceiptDetail] = useState<boolean>(false);

  const [zoomDisabled, setZoomDisabled] = useState<boolean>(false);
  const [zoomButtonMessage, setZoomButtonMessage] = useState<string>('')

  const isClinic = reservation?.facility.type === FacilityType.clinic
  const isPharmacy = reservation?.facility.type === FacilityType.pharmacy

  useEffect(() => {
    if (reservation_id) {
      fetchReservation(reservation_id);
    }
  }, [authUser, reservation_id]);

  // 初期表示
  const fetchReservation = async (reservationId: string) => {
    if (!authUser) {
      return;
    }

    try {
      const response = await getReservationWithFacility(reservationId, {user_id: authUser.id});
      setReservation(response.data);
    } catch (error) {
      console.error('予約の取得に失敗しました', error);
    }
  };

  useEffect(() => {
    if (reservation) {
      // 関連予約情報取得
      if (isClinic) {
        fetchClinicRelateReservation(reservation.id)
      } else if (isPharmacy) {
        fetchPharmacyRelateReservation(reservation.id)
      }

      // 請求情報取得
      fetchPayment(reservation.id)

      // zoom連携ボタン制御
      // zoom URLが空の場合は無効
      if (reservation.zoomUrl === '') {
        setZoomButtonMessage('Zoom URLが無効です。管理者にお問い合わせください')
        setZoomDisabled(true)
        return
      }
      // 予約日以外は無効
      let today = new Date().toLocaleDateString()
      let reservationDate = new Date(reservation.reservationTime).toLocaleDateString()
      if (today !== reservationDate) {
        setZoomButtonMessage('予約日になるとボタンを押すことができます')
        setZoomDisabled(true)
      }

      // 予約情報の「診療明細を見る」ボタンのトグル
      if (isClinic && reservation.receiptFiles) {
        setHasReceiptDetail(true)
      }
    }
  }, [reservation])

  // クリニック予約に紐づく関連予約の取得
  const fetchClinicRelateReservation = async (reservation_id: number) => {
    const result = await getClinicReservationRelation(reservation_id);
    const clinicRelateReservations = result.data.map((record: ClinicReservationRelation) => {
      return({
        reservationId: record.reservationPharmacyId,
        reservationTime: record.reservationPharmacy.reservationTime,
        facilityName: record.reservationPharmacy.facility.nameShort || record.reservationPharmacy.facility.name,
        facilityAddress:
          zipAndAddress(record.reservationPharmacy.facility.zip, record.reservationPharmacy.facility.address),
        tel: record.reservationPharmacy.facility.tel,
        fax: record.reservationPharmacy.facility.fax,
        facilityType: FacilityType.pharmacy
      } as RelateReservation)
    })
    setRelationReservations(clinicRelateReservations);
  }

  // 薬局予約に紐づく関連予約の取得
  const fetchPharmacyRelateReservation = async (reservation_id: number) => {
    const result = await getPharmacyReservationRelation(reservation_id);
    const pharmacyRelateReservations = result.data.map((record: PharmacyReservationRelation) => {
      return({
        reservationId: record.reservationClinicId,
        reservationTime: record.reservationClinic.reservationTime,
        facilityName: record.reservationClinic.facility.nameShort || record.reservationClinic.facility.name,
        facilityAddress:
          zipAndAddress(record.reservationClinic.facility.zip, record.reservationClinic.facility.address),
        tel: record.reservationClinic.facility.tel,
        fax: record.reservationClinic.facility.fax,
        facilityType: FacilityType.clinic
      } as RelateReservation)
    })
    setRelationReservations(pharmacyRelateReservations);
  }

  // 請求情報取得
  const fetchPayment = async (reservation_id: number) => {
    const result = await getPaymentByReservation(reservation_id)
    if (result.data) {
      console.log({payment: result.data})
      setPayment({
        id: result.data.id,
        amount: result.data.amount,
        status: result.data.status,
        paymentLink: result.data.fincodePayment.paymentLink,
      } as PaymentLimitedType)
    }
  }

  // 処方せん編集画面遷移
  const moveToPrescriptionEdit = (reservation_id: number, user_id: number) => {
    navigation(`prescription`, {state: {reservation_id: reservation_id, user_id: user_id}})
  }

  // 予約に紐づく診療明細遷移
  const moveToReceiptDetail = (reservation_id: number) => {
    navigation(`/receipt_detail/${reservation_id}`, {state: {reservation_id: reservation_id}})
  }

  // 住所項目の整形
  const zipAndAddress = (zip: string | undefined, address: string | undefined) => {
    let addressStr = ''
    if (zip) addressStr += `〒${zip} `
    if (address) addressStr += address
    return addressStr
  }

  // 関連する予約の情報
  const relateReservationInfo = () => {

    if (isClinic && relationReservations.length === 0) return
    if (isPharmacy && relationReservations.length === 0 &&
        !reservation.onlyMedWithRx && !reservation.onlyMedNoRx) return

    const facilityLabel = isClinic ? '薬局' : 'クリニック'
    const boxTitle = isClinic ? '服薬指導の予約情報' : '予約時に選択した予約等の情報';

    return (
      <>
        <Typography sx={{ ...customStyles.TypographyPageTitle, mt: 8, textAlign: "left" }}>
          {boxTitle}
        </Typography>

        {relationReservations.map((relationReservation) => (
          <>
            <Grid container sx={customStyles.gridContainer} spacing={2}>
              <Grid item xs={2} sx={[customStyles.gridItem,{ marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  予約日時:
                </Typography>
              </Grid>
              <Grid item xs={8} sx={[customStyles.gridItem,{ marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  {formattedDatetime(relationReservation.reservationTime)}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={[customStyles.gridItem,{ marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  {facilityLabel}:
                </Typography>
              </Grid>
              <Grid item xs={8} sx={[customStyles.gridItem,{ marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  {relationReservation.facilityName}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={[customStyles.gridItem,{ marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  所在地:
                </Typography>
              </Grid>
              <Grid item xs={8} sx={[customStyles.gridItem,{ marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  {relationReservation.facilityAddress}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={[customStyles.gridItem,{ marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  TEL:
                </Typography>
              </Grid>
              <Grid item xs={8} sx={[customStyles.gridItem,{ marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  {relationReservation.tel}
                </Typography>
              </Grid>
              <Grid item xs={2} sx={[customStyles.gridItem,{ marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  FAX:
                </Typography>
              </Grid>
              <Grid item xs={8} sx={[customStyles.gridItem,{ marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  {relationReservation.fax}
                </Typography>
              </Grid>
            </Grid>
          </>
        ))}

        {/* オンライン診療に紐づかない予約（手元処方せんあり） */}
        {isPharmacy && reservation?.onlyMedWithRx && (
          <>
            <Grid container sx={customStyles.gridContainer} spacing={2}>
              <Grid item xs={7} sx={[customStyles.gridItem, { marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  オンライン診療ではない服薬指導の予約
                  <br />
                  （処方せんは手元にもっている）
                </Typography>
              </Grid>
              <Grid item xs={4} sx={[ customStyles.gridItem, { marginLeft: "10px", paddingTop: "0px" }]}>
                <Button
                  sx={customStyles.buttonSingle}
                  color="primary"
                  variant="contained"
                  size="medium"
                  startIcon={
                    <icons.MedicinesIcon style={{ width: 15, height: 15 }} />
                  }
                  onClick={() =>
                    moveToPrescriptionEdit(reservation.id, reservation.userId)
                  }
                >
                  処方せんを見る
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {/* オンライン診療に紐づかない予約（手元処方せんなし） */}
        {isPharmacy && reservation?.onlyMedNoRx && (
          <>
            <Grid container sx={customStyles.gridContainer} spacing={2}>
              <Box sx={{ height: 0, borderTop: "1px solid #d53333", marginY: 2 }} />

              <Grid item xs={8} sx={[customStyles.gridItem, { marginLeft: "10px", paddingTop: "0px" }]}>
                <Typography sx={{ ...customStyles.boldLabel, fontSize: "0.75rem" }}>
                  オンライン診療ではない服薬指導の予約
                  <br />
                  （処方せんは医療機関にある）
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }

  // 詳細項目Grid
  const gridItemWithLabel = (label: string, value: string, IconComponent: React.ComponentType) => {
    return (
      <Grid container sx={[customStyles.gridContainer, {marginTop: '0',paddingTop: '0'}]} spacing={2}>
        <Grid item xs={1} sx={[customStyles.gridItem, {paddingLeft: '5px'}]}>
        <Box component={IconComponent} sx={customStyles.icon} />
        </Grid>
        <Grid item xs={3} sx={customStyles.gridItem}>
          <Typography sx={customStyles.boldLabel}>{label}</Typography>
        </Grid>
        <Grid item xs={8} sx={customStyles.gridItem}>
          {value}
        </Grid>
      </Grid>
    );
  }

  // 支払い情報Grid
  const gridItemPayments = (label: string, value: string) => {
    return (
      <Grid container sx={[customStyles.gridContainer, { marginTop: "0", paddingTop: "0" }]} spacing={2}>
        <Grid item xs={8} sx={[customStyles.gridItem, { marginLeft: "10px" }]}>
          <Typography sx={customStyles.boldLabel}>{label}</Typography>
        </Grid>
        <Grid item xs={2} sx={customStyles.gridItem}>
          <Typography sx={customStyles.boldLabel}>{value}</Typography>
        </Grid>
      </Grid>
    );
  }

  // 予約ステータスChip
  const reservationStatusChip = (status: number) => {
    let label = '未完了'
    let style = 'warn' as ChipType
    if (status === ReservationStatus.Completed) {
      label = '完了'
      style = 'info' as ChipType
    }
    return (
      <Chip label={label} type={style} />
    )
  }

  // 決済ステータスChip
  const paymentStatusChip = (status: string) => {
    let label = '未支払'
    let style = 'warn' as ChipType
    if (["paid"].includes(status)) {
      label = '支払済'
      style = 'info' as ChipType
    }
    return (
      <Chip label={label} type={style} />
    )
  }

  return (
    <LayoutWithMenu>
      <Typography sx={{ ...customStyles.TypographyPageTitle, mt: 8, ml: 3, textAlign: "left"}}>
        予約情報
        {reservation && reservationStatusChip(reservation.status)}
      </Typography>

      {!reservation &&
        <Container maxWidth={false} sx={{mb: 3}}>
          <FormHelperText error={true}>データがありません</FormHelperText>
        </Container>
      }

      {reservation &&
        <>
          <Container maxWidth={false}>
              {gridItemWithLabel("予約日時", formattedDatetime(reservation.reservationTime),iconsList[1])}
              {gridItemWithLabel("施設名", reservation.facility ? reservation.facility.name : "", iconsList[1])}
              {gridItemWithLabel("住所", zipAndAddress(reservation.facility.zip, reservation.facility.address), iconsList[3])}
              {gridItemWithLabel( "電話番号", reservation.facility?.tel ?? "", iconsList[2])}
          </Container>

          <Box>
            {/* 予約が完了している場合はZoomへの連携ボタンを表示しない */}
            {reservation.status !== ReservationStatus.Completed && (
              <React.Fragment>
                <Box sx={customStyles.ButtonContainer}>
                  <Button
                    sx={customStyles.buttonSingle}
                    color="primary"
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      window.open(reservation.zoomUrl, "_blank");
                    }}
                    disabled={zoomDisabled}
                  >
                    {reservation.facility.type === "Clinic"
                      ? "オンライン診療を受ける(ZOOM)"
                      : "オンライン服薬指導を受ける(ZOOM)"}
                  </Button>
                </Box>
                <Box sx={customStyles.messageContainer}>
                  <FormHelperText error={true}>{zoomButtonMessage}</FormHelperText>
                </Box>
              </React.Fragment>
            )}
            <Box sx={customStyles.ButtonContainer}>
              {hasReceiptDetail && <Button
                sx={customStyles.buttonSingle}
                variant="contained"
                size="medium"
                onClick={() => moveToReceiptDetail(reservation.id)}
              >
                診療明細を見る
              </Button>}
            </Box>

          </Box>

          {payment && <>
            <Typography sx={{...customStyles.TypographyPageTitle, mt: 8, ml: 3, textAlign: "left" }}>
              支払い情報
              {paymentStatusChip(payment.status)}
            </Typography>

            <Container maxWidth={false}>
              {gridItemPayments((isClinic ? '診察料' : '患者負担金'), `${payment?.amount.toLocaleString()}円`)}
              {gridItemPayments("合計", `${payment?.amount.toLocaleString()}円`)}
            </Container>
            <Box sx={customStyles.ButtonContainer}>
              {["unpaid", "price_changed"].includes(payment.status) && <Button
                sx={customStyles.buttonSingle}
                color="primary"
                variant="contained"
                size="medium"
                component={Link}
                to={payment.paymentLink}
                target="_blank"
              >
                支払いする
              </Button>}
            </Box>
          </>}

          {/* 関連する予約の情報 */}
          <Container maxWidth={false}>{relateReservationInfo()}</Container>

          {/* お薬手帳を見るボタン */}
          <Box sx={customStyles.ButtonContainer}>
            <Button
              sx={customStyles.buttonSingle}
              color="primary"
              variant="contained"
              size="medium"
              startIcon={
                <icons.NotebookLinearIcon style={{ width: 15, height: 15 }} />
              }
              onClick={() => navigation("/medicine_notebook")}
            >
              お薬手帳を見る
            </Button>
          </Box>

          {/* 保険証を見るボタン */}
          <Box sx={customStyles.ButtonContainer}>
            <Button
              sx={customStyles.buttonSingle}
              color="primary"
              variant="contained"
              size="medium"
              startIcon={
                // アイコンの横にマージンを追加し上下で位置を揃える
                <icons.IdCardOutlineIcon
                  style={{ width: 15, height: 15, marginRight: 10 }}
                />
              }
              onClick={() => navigation("/insurance_card")}
            >
              保険証を見る
            </Button>
          </Box>
        </>
      }

      {/* 戻るボタン */}
      <Box sx={customStyles.ButtonContainer}>
        <Button
          sx={[customStyles.buttonOutlined, {width: '19.5em'}]}
          color="primary"
          variant="outlined"
          size="medium"
          onClick={() => navigation("/top")}
        >
          {ButtonCaption.back}
        </Button>
      </Box>

    </LayoutWithMenu>
  );
}

export default ReservationDetail;
