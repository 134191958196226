import React, { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Link,
  FormHelperText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { getClinicList } from '../lib/api/clinic'; 
import { getUserDetail } from '../lib/api/user';
import { useAuthUserContext } from '../contexts/AuthUserContext'
import { useUserInfoRegistered } from '../hooks/useUserInfoRegistered'

import { MedicalInstitutionFormData } from './schema/FacilitySchema';
import LayoutWithMenu from './LayoutWithMenu';
import { ButtonCaption, FacilityTypeCode, Messages } from "../utils/Constants";
import { moveToChoiceReserve, MoveToChoiceReserveParam } from '../utils/choiceReserve';
import CustomDialog from './parts/CustomDialog';
import { icons } from "../icon";

const styles = {
  noteMobile: {
    pb: 1,
    fontSize: 12,
    display: { xs: 'block', sm: 'none' },
  },
  notePC: {
    pb: 1,
    fontSize: 12,
    display: { xs: 'none', sm: 'block' },
    color: '#000000',
    marginBottom: '20px',
  },
  GridContainer: {
    borderBottom: 1,
    borderColor: 'lightGrey',
    fontSize: 14,
    textDecoration: 'none',
    color: '#000000',
    py: 2,
    backgroundColor: "#F5FAFD !important",
    paddingTop: 1,
    paddingBottom: 1
  },
  GridItem: {
    alignContent: 'center',
    backgroundColor: "#F5FAFD !important"
  },
  LinkStyle: {
    cursor: 'pointer',
    color: '#003642', // リンクの色
    textDecoration: 'none', // アンダーラインを消す
    '&:hover': {
      textDecoration: 'underline', // ホバー時にアンダーラインを表示
      color: '#1aa3c9'
    },
    fontWeight: 400,
  },
  DepartmentLabel: {
    fontSize: 12,
    marginTop: 3,
    marginLeft: 30,
  }
}

const MedicalInstitutionList = () => {

  const [clinicDirectId, setClinicDirectId] = useState<string>('');
  const [medicalInstitutions, setMedicalInstitutions] = useState<Array<MedicalInstitutionFormData>>([]);

  // dialog用
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<string>('');

  // ユーザー情報が登録されているか
  const {isUserInfoRegistered, userErrorMessage, userNotificationMessage} = useUserInfoRegistered()
  
  const navigation = useNavigate();
  const authUser = useAuthUserContext().authUser

  // 初期表示処理
  useEffect(() => {
    initProc()
  },[])

  const initProc = async () => {
    await displayData();
    await isUserInfoRegistered();
  }

  // 初期表示データ取得
  const displayData = async () => {
    try {
      const response = await getClinicList();
      setMedicalInstitutions(response.data);
    } catch (error) {
      console.error("医療機関一覧の取得に失敗しました", error);
    }
  }

  // 詳細画面遷移
  const moveToDetail = (data: MedicalInstitutionFormData) => {
    navigation(`detail/${data.id}`);
  }

  // 外部サイト遷移確認
  const openMoveConfirmDialog = (direct_id: string) => {
    setClinicDirectId(direct_id)
    setDialogContent(Messages.moveExternalSiteConfirm)
    setIsDialogOpen(true);
  }

  // choiceRESERVE予約画面への遷移
  const moveToChoiceReserveSite = () => {
    const moveToParam = {
      directId: clinicDirectId,
      userId: authUser?.id,
      facilityTypeCode: FacilityTypeCode.clinic,
    } as MoveToChoiceReserveParam;
    moveToChoiceReserve(moveToParam);
  }

  return (
    <LayoutWithMenu pageTitle={'診療予約（医療機関）'}>
      {/* <Typography sx={styles.noteMobile}>※ 医療機関名をタップすると詳細情報を表示します</Typography>
      <Typography sx={styles.notePC}>※ 医療機関名をクリックすると詳細情報を表示します</Typography> */}
      <FormHelperText error={true} sx={{whiteSpace: 'pre-line'}}>{userErrorMessage}</FormHelperText>
      <FormHelperText sx={{whiteSpace: 'pre-line'}}>{userNotificationMessage}</FormHelperText>
      {medicalInstitutions.map((data, index) => {
        return (
          <Grid container key={index} sx={styles.GridContainer}>
            <Grid container item xs={1} sx={[styles.GridItem, {paddingLeft: '5px'}]}>
            <icons.HospitalIcon style={{ width: "20px", height: "20px", marginRight: "0px" }} />
            </Grid>
            <Grid container item xs={7} sx={[styles.GridItem, {paddingLeft: '1px'}]}>
              <Link sx={styles.LinkStyle} onClick={ () => moveToDetail(data) }>
                {data.nameShort || data.name} 
              </Link>
              <span style={styles.DepartmentLabel}>
                {data.department}
              </span>
            </Grid>
            <Grid container item xs={3} sx={styles.GridItem}>
              <Button
                sx={{
                  backgroundColor: '#21bddb',
                  boxShadow: 'none',
                  fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: '#1aa3c9',
                    boxShadow: 'none',
                  },
                }}
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => openMoveConfirmDialog(data.directId || '')}
                disabled={userErrorMessage !== '' || !data.directId}
              >
                {ButtonCaption.reservation}
                </Button>
            </Grid>
            { !data.directId &&
                  <FormHelperText
                    error={true}
                    sx={{ whiteSpace: 'pre-line', fontSize: '0.7em',paddingLeft: '10px'  }}
                  >
                    {Messages.cannotReservation}
                  </FormHelperText> }
          </Grid>
        )
      })}

      <CustomDialog
        isOpen={isDialogOpen}
        content={dialogContent}
        onConfirm={() => moveToChoiceReserveSite()}
        onCancel={() => setIsDialogOpen(false)}
      />

    </LayoutWithMenu>
  )
}
export default MedicalInstitutionList