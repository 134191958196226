import { useState } from 'react'
import {
  Button,
  Grid,
  Stack,
  TextField,
  AlertColor,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordEditFormData, PasswordEditSchema } from './schema/PasswordUpdateSchema';

import { Snackbar } from './parts/Snackbar';
import { ButtonCaption } from "../utils/Constants";

import { styles } from './style/commonStyles'
import LayoutWithMenu from './LayoutWithMenu';

import { useAuthUserContext } from '../contexts/AuthUserContext'

const UserPasswordEdit = () => {
  const passwordUpdateFromPrevious = useAuthUserContext().passwordUpdateFromPrevious

  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(PasswordEditSchema),
  })

  // フォーム送信時の処理
  const onSubmit: SubmitHandler<PasswordEditFormData> = async (data) => {
    const password_previous = data.password_previous
    const password = data.password
    const password_confirmation = data.password_confirmation
    if ( !(password_previous && password && password_confirmation ) ) {
      console.log("TODO: validation実装: password_previous or password or password_confirmation is empty!")
      return false
    }
    const params: PasswordEditFormData = {password_previous, password, password_confirmation}

    passwordUpdateFromPrevious(
      params, {},
      () => {
        setSnackbarProps('success', 'パスワードが更新されました', true);
        reset({password_previous: '', password: '', password_confirmation: ''})
      },
      () => {
        setSnackbarProps('warning', 'パスワードを更新出来ませんでした', true);
      },
    )
  }

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }

  return (
    <LayoutWithMenu>

      <Stack spacing={5} sx={{mt: 10}}>
        <Typography sx={{mt: 4, pb: 2, fontSize:24, fontWeight: 'bold'}} align='center'>パスワード変更</Typography>
      </Stack>

      <Stack spacing={6} sx={{pt: 3}}>
      <Stack spacing={4}>

        <TextField
          required
          label="現在のパスワード"
          type="password"
          InputLabelProps={{ shrink: true }}
          {...register('password_previous')}
          error={"password_previous" in errors}
          helperText={errors.password_previous?.message}
          placeholder='パスワードを入力してください'
          sx={styles.TextField}
          />

        <TextField
          required
          label="新しいパスワード"
          type="password"
          InputLabelProps={{ shrink: true }}
          {...register('password')}
          error={"password" in errors}
          helperText={errors.password?.message}
          placeholder='パスワードを入力してください'
          sx={styles.TextField}
          />

        <TextField
          required
          label="新しいパスワード（確認）"
          type="password"
          InputLabelProps={{ shrink: true }}
          {...register('password_confirmation')}
          error={"password_confirmation" in errors}
          helperText={errors.password_confirmation?.message}
          placeholder='パスワードを入力してください'
          sx={styles.TextField}
          />
      </Stack>

      <Grid container alignItems='center' justifyContent="space-around" direction="row">
        <Grid item xs={5}>
          <Button
            sx={styles.buttonOutlined}
            variant="outlined"
            onClick={() => navigate(-1)}
          >
            {ButtonCaption.back}
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            sx={styles.buttonSimple} 
            onClick={handleSubmit(onSubmit)}
          >
            {ButtonCaption.edit}
          </Button>
        </Grid>
      </Grid>

      </Stack>
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  )
}
export default UserPasswordEdit