import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import LayoutWithMenu from "../LayoutWithMenu";
import { ReservedUser } from "../../interfaces";
import { getReservedUserList } from "../../lib/api/user";
import { useAuthFacilityStaffContext } from '../../contexts/AuthFacilityStaffContext'
import ImageModal from '../../components/parts/ImageModal';

const styles = {
  GridContainer: {
    borderBottom: 1,
    borderColor: 'lightGrey',
    py: 1,
    fontSize: 16,
    textDecoration: 'none',
    color: '#000000',
    paddingTop: 5,
    paddingBottom: 5
  },
  GridItem: {
    alignContent: 'center',
  }
}

type Props = {
  reservedUsers: ReservedUser[]
  imagePropName: "hicardImages" | "medicineNotebookImages"
}

const UserImageListCommon: React.FC<Props> = ({reservedUsers, imagePropName}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalImgSrc, setModalImgSrc] = useState<string>('')

  // Modal表示内容セット
  const setModalContent = (url: string) => {
    setModalImgSrc(url)
    setModalOpen(true)
  }
  return (
    <>
      {reservedUsers.map((user, index) => (
        <Box key={index} sx={styles.GridContainer}>
          <h3>患者名: {user.name}</h3>
          <h3>予約日時: {user.reservationTime}</h3>
          {user[imagePropName].length > 0 ?
            <Grid container spacing={2}>
              {user[imagePropName].map((image, index) => (
                <Grid item xs={6} md={4} sx={styles.GridItem} key={index}>
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      aspectRatio: '1 / 1'
                    }}
                    src={image.url}
                    onClick={() => setModalContent(image.url)}
                  />
                </Grid>
              ))}
            </Grid>
            :
            <p>登録無し</p>
          }
        </Box>
      ))}
      <ImageModal
        open={modalOpen}
        setOpen={setModalOpen}
        imgSrc={modalImgSrc}
      />
    </>
  )
}
export default UserImageListCommon;
