import { createContext, useState, useContext, ReactNode } from 'react'
import Cookies from "js-cookie"
import { AxiosRequestConfig, AxiosHeaders, AxiosError } from "axios"

import {
  signIn as apiSignIn,
  signUp as apiSignUp,
  signOut as apiSignOut,
  passwordReset as apiPasswordReset,
  passwordUpdate as apiPasswordUpdate,
  getCurrentUser as apiGetCurrentUser,
} from "lib/api/facility_staff_auth";
import { FacilityUser, SignInParams, SignUpParams, PasswordResetParams, PasswordUpdateParams } from "interfaces/index"
import { PasswordResetFormData, PasswordResetSchema } from '../components/schema/PasswordResetSchema';

// TODO: 型定義の場所など見直し
type FacilityStaffSignUpParams = SignUpParams & { name?: string }

export type AuthFacilityStaffContextType = {
  authFacilityStaff: FacilityUser | null;
  signUp: (params: FacilityStaffSignUpParams, callback: () => void, errorCallback: (error: any) => void) => Promise<void>;
  signIn: (params: SignInParams, callback: (facilityStaff: any) => void, errorCallback: () => void) => Promise<void>;
  signOut: (callback:() => void) => void;
  passwordReset: (params: PasswordResetFormData, callback: () => void, errorCallback: () => void) => Promise<void>;
  passwordUpdate: (params: PasswordUpdateParams, options: AxiosRequestConfig, callback: () => void) => Promise<void>;
  getCurrentUser: () => Promise<boolean>;
}
const AuthFacilityStaffContext = createContext<AuthFacilityStaffContextType>({} as AuthFacilityStaffContextType);

export const useAuthFacilityStaffContext = ():AuthFacilityStaffContextType => useContext<AuthFacilityStaffContextType>(AuthFacilityStaffContext);

type Props = {
  children: ReactNode
}

export const AuthFacilityStaffProvider = (props:Props) => {
  const [authFacilityStaff, setAuthFacilityStaff] = useState<FacilityUser | null>(null);

  const signUp = async (params: FacilityStaffSignUpParams, callback: () => void, errorCallback: (error: any) => void) => {
    try {
      const {data, headers} = await apiSignUp(params) as { data: {data: FacilityUser, status: string}, headers: {[key: string]: string}}
      console.log({data})
      if (data.status === "success") {
        const user = data.data
        console.log("施設スタッフサインアップ成功")
        callback();
      }
    } catch (error) {
      console.log("サインアップエラー")
      console.log(error)
      errorCallback(error);
    }
  }

  const signIn = async (params: SignInParams, callback: (facilityStaff: any) => void, errorCallback: () => void) => {
    try {
      // console.log('サインイン')
      // console.log({params})
      const {data, headers} = await apiSignIn(params) as { data: {data: FacilityUser, message: string}, headers: {[key: string]: string}}

      if (data.message === 'require OTP') {
        // 呼び出し側でワンタイムパスワード認証ステップに進む
        callback(data.message);
        return
      }
      const facilityStaff = data.data
      // console.log({user, headers})
      setAuthFacilityStaff(facilityStaff);

      // ログインに成功した場合はCookieに各値を格納
      Cookies.set("_f_access_token", headers["access-token"])
      Cookies.set("_f_client", headers["client"])
      Cookies.set("_f_uid", headers["uid"])

      callback(facilityStaff);
    } catch (error) {
      // console.log("サインインエラー")
      console.log(error)
      errorCallback();
    }
  }

  const signOut = async (callback: () => void) => {
    try {
      const res = await apiSignOut() as { success: boolean }
      if (res.success) {
        // console.log("サインアウト成功")
        setAuthFacilityStaff(null);
        callback();
      }
    } catch (error) {
      console.log("サインアウトエラー")
      console.log(error)
    }
  }

  const passwordReset = async (params: PasswordResetFormData, callback: () => void, errorCallback: () => void) => {
    try {
      const res = await apiPasswordReset(params) as {success: boolean, message: string}
      // console.log({res})
      if (res.success) {
        callback();
      }
    } catch (error) {
      // console.log("パスワードリセットエラー")
      console.log(error)
      errorCallback();
    }
  }

  const passwordUpdate = async (params: PasswordUpdateParams, options: AxiosRequestConfig, callback: () => void) => {
    try {
      const res = await apiPasswordUpdate(params, options) as {success: boolean, data: FacilityUser, message: string}
      if (res.success) {
        // console.log("パスワード更新成功")
        callback();
      }
    } catch (error: any) {
      console.log("パスワード更新エラー")
      console.log(error)
      // const errorMessage: string = error.response.data.errors.fullMessages
    }
  }

  const getCurrentUser = async () => {
    try {
      const res = await apiGetCurrentUser()
      // console.log('getCurrentUser', {res})
      if (res?.isLogin === true) {
        setAuthFacilityStaff(res?.data)
        return true
      } else {
        console.log("No current user")
      }
      return false
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const value:AuthFacilityStaffContextType = { authFacilityStaff, signUp, signIn, signOut, passwordReset, passwordUpdate, getCurrentUser };

  return (
    <AuthFacilityStaffContext.Provider value={value}>
      {props.children}
    </AuthFacilityStaffContext.Provider>
  );
}
