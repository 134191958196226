import LayoutWithMenu from "./LayoutWithMenu";
import {UserReservation} from "../interfaces";
import React, {useEffect, useState} from "react";
import {getReservationListByUser} from "../lib/api/reservation";
import {useAuthUserContext} from "../contexts/AuthUserContext";
import {Button, Grid, Link} from "@mui/material";
import {ButtonCaption} from "../utils/Constants";
import {formattedDatetime} from "../utils/helper";
import {useNavigate} from "react-router-dom";

const styles = {
  GridContainer: {
    borderBottom: 1,
    borderColor: 'lightGrey',
    py: 1,
    fontSize: 16,
    textDecoration: 'none',
    color: '#000000',
    paddingTop: 5,
    paddingBottom: 5
  },
  GridItem: {
    alignContent: 'center',
  }
}

const ReservationList = () => {
  const [reservations, setReservations] = useState<UserReservation[]>([]);
  const authUser = useAuthUserContext().authUser;
  const navigation = useNavigate();

  useEffect(() => {
    fetchReservations();
  }, [authUser]);

  // 初期表示
  const fetchReservations = async () => {
    if (!authUser) {
      return;
    }

    try {
      const response = await getReservationListByUser(authUser.id.toString());
      setReservations(response.data);
    } catch (error) {
      console.error('予約一覧の取得に失敗しました', error);
    }
  };

  // 詳細画面遷移
  const moveToDetail = (id: number) => {
    // TODO: 遷移先の画面を作成する
  }


  return (
    <LayoutWithMenu
      pageTitle={'予約リスト'}
    >
      {reservations.map((reservation, index) => (
        <Grid container key={index} sx={styles.GridContainer}>
          <Grid container item xs={10} sx={styles.GridItem}>
            <div>
              <p>日時: {formattedDatetime(reservation.reservationTime)}</p>
              <p>
                {reservation.facility.type === 'Clinic' ? '医療機関名' : '薬局名'}: {reservation.facility.name}
              </p>
            </div>
          </Grid>
          <Grid container item xs={2} sx={styles.GridItem}>
            <Button
              sx={{
                backgroundColor: '#21bddb',
                boxShadow: 'none',
                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: '#1aa3c9',
                  boxShadow: 'none',
                },
              }}
              color="primary"
              variant="contained"
              size="medium"
              onClick={ () => moveToDetail(reservation.id) }
            >
              {ButtonCaption.reservationDetail}
            </Button>
          </Grid>
        </Grid>
      ))}
    </LayoutWithMenu>
  )
}

export default ReservationList;
