import { useState } from "react"

import {
  Button,
  Stack,
  Grid,
  TextField,
  Typography,
  AlertColor,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordResetFormData, PasswordResetSchema } from '../schema/PasswordResetSchema';

import { Snackbar } from '../parts/Snackbar';
import { ButtonCaption } from "../../utils/Constants";

import LayoutWithMenu from '../LayoutWithMenu';

import { useNavigate } from 'react-router-dom';
import { useAuthAdminUserContext } from '../../contexts/AuthAdminUserContext'

const AdminUserPasswordReset = () => {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search)
  const passwordResetSuccess = searchParams.get('password_reset_success') || ''

  const passwordReset = useAuthAdminUserContext().passwordReset

  const [sentMail, setSentMail] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  const onSubmit: SubmitHandler<PasswordResetFormData> = (data) => {
    const email = data.email
    const params: PasswordResetFormData = {email, redirect_url: `${process.env.REACT_APP_ROOT_URL}/admin/password_update`}

    passwordReset(
      params,
      () => {
        console.log('パスワードリセット!')
        setSentMail(true)
      },
      () => {
        setSnackbarProps('warning', 'メールアドレスを確認出来ませんでした', true);
      }
    )
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PasswordResetFormData>({
    resolver: yupResolver(PasswordResetSchema),
  })

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }
  
  return (
    <LayoutWithMenu
      pageTitle={'パスワードの再発行'}
      hideNavLinks={true}
    >
      { sentMail ? <>
        <Typography  sx={{m: 3}} variant="subtitle2" align='center'>パスワードの変更依頼を受け付けました。数分以内にメールを送信いたしますので、内容をご確認ください。</Typography>
      </> : <>
        { passwordResetSuccess === 'false' &&
          <Typography  sx={{m: 3}} variant="subtitle2" align='center'>パスワード変更完了までの有効期限が切れています。<br />お手数ですが、パスワードの再発行手続きをお願いします。</Typography>
        }
        <Typography  sx={{m: 3}} variant="subtitle2" align='center'>登録しているメールアドレスに仮パスワードを送信します。</Typography>
        <Stack spacing={3} sx={{pt: 3}}>

          <TextField
            required
            label="メールアドレス"
            InputLabelProps={{ shrink: true }}
            {...register('email')}
            error={"email" in errors}
            helperText={errors.email?.message}
          />

          <Grid container justifyContent="end" spacing={2}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                onClick={handleSubmit(onSubmit)}
              >
                {ButtonCaption.send}
              </Button>
            </Grid>
            <Grid item>
              <Button
                  color="primary"
                  variant="outlined"
                  size="medium"
                  onClick={() => navigate(-1)}
                >
                  {ButtonCaption.cancel}
                </Button>
              </Grid>
          </Grid>

        </Stack>
      </>
      }
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
}
export default AdminUserPasswordReset