import yup from "../../utils/yup.locale";

// 施設登録・編集フォーム（共通）の型
export class FacilityFormData  {
  name: string = ''
  nameShort: string = ''
  kana: string = ''
  department?: string = ''
  information?: string = ''
  operatingHours: string = ''
  note?: string = ''
  tel: string = ''
  fax?: string = ''
  zip: string = ''
  address: string = ''
  categoryId?: string = ''
  directId: string = ''
  fincodeShopId: string = ''
}

// 医療機関一覧、詳細画面フォームの型
export class MedicalInstitutionFormData extends FacilityFormData {
  id: string = ''
  // 独自のものがあれば追加する
}

// 薬局一覧、詳細画面フォームの型
export class PharmacyFormData extends FacilityFormData {
  id: string = ''
  // 独自のものがあれば追加する
}

export class FacilityNewFormData  {
  name: string = ''
}

const facilitySchemaShapeObjBase = {
  name: yup.string().required(),
  kana: yup.string().required(),
  nameShort: yup.string().required(),
  information: yup.string(),
  operatingHours: yup.string().required(),
  note: yup.string(),
  zip: yup.string().required()
    .matches(/^\d{7}$/, '郵便番号は半角数字7桁で入力してください'),
  address: yup.string().required(),
  tel: yup.string()
    .required('電話番号は必須です')
    .matches(/^\d{10,11}$/, '電話番号は半角数字10桁または11桁で入力してください'),
  fax: yup.string()
    .matches(/^\d{10,11}$/, 'ファックス番号は半角数字10桁または11桁で入力してください'),
}

const facilitySchemaShapeObjAdmin = {
  ...facilitySchemaShapeObjBase,
  categoryId: yup.string(),
  directId: yup.string()
}

const facilitySchemaShapeObjClinic =  {
  ...facilitySchemaShapeObjBase,
  department: yup.string().required(),
}

// バリデーションルール
export const FacilitySchema = yup.object().shape(facilitySchemaShapeObjBase);

export const FacilityAdminSchema = yup.object().shape(facilitySchemaShapeObjAdmin);

export const ClinicSchema = yup.object().shape(facilitySchemaShapeObjClinic);

export const PharmacySchema = yup.object().shape(facilitySchemaShapeObjBase);

export const FacilityNewSchema = yup.object().shape({
  name: yup.string().required(),
});