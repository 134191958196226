import { ReactComponent as HospitalIcon } from "./hospital.svg";
import { ReactComponent as MedicinesIcon } from "./medicines.svg";
import { ReactComponent as NotebookLinearIcon } from "./notebook-linear.svg";
import { ReactComponent as IdCardOutlineIcon } from "./id-card-outline.svg";
import { ReactComponent as DocumentOutlineIcon } from "./document-outline.svg";
import { ReactComponent as PlusIcon } from "./plus.svg";

type IconComponent = React.FC<React.SVGProps<SVGSVGElement>>;

export const icons: { [key: string]: IconComponent } = {
  HospitalIcon,
  MedicinesIcon,
  NotebookLinearIcon,
  IdCardOutlineIcon,
  DocumentOutlineIcon,
  PlusIcon,
};
