import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FacilityFormData } from '../schema/FacilitySchema';
import LayoutWithMenu from '../LayoutWithMenu';
import FacilityDetailCommon from './FacilityDetailCommon';
import { getClinicDetail } from '../../lib/api/clinic';
import { getPharmacyDetail } from '../../lib/api/pharmacy';
import { styles } from '../style/commonStyles';
import { ButtonCaption, FacilityType } from '../../utils/Constants';
import { useAuthFacilityStaffContext } from '../../contexts/AuthFacilityStaffContext'

const FacilityDetail = () => {
  const authFacilityStaff = useAuthFacilityStaffContext().authFacilityStaff;
  const navigate = useNavigate();
  const [facilityId, setFacilityId] = useState<string>(authFacilityStaff?.facilityId.toString() || '');
  const [facilityData, setFacilityData] = useState<FacilityFormData>(new FacilityFormData());
  
  const facilityType = authFacilityStaff?.facilityType;

  // 初期表示処理
  useEffect(() => {
    if (facilityId !== null) {
      switch (facilityType) {
        case FacilityType.clinic:
          getClinicData();
          return;
        case FacilityType.pharmacy:
          getPharmacyData();
          return;
        default:
        console.log(`未定義の施設タイプ ${facilityType}`)  
        return;
      }
    }
  }, []);

  // 施設情報（クリニック）取得
  const getClinicData = async () => {
    try {
      const response = await getClinicDetail(facilityId)
      setFacilityId(facilityId);
      setFacilityData(response.data);
    } catch (error) {
      console.error("クリニック情報の取得に失敗しました", error);
    }
  }
  
  // 施設情報（薬局）取得
  const getPharmacyData = async () => {
    try {
      const response = await getPharmacyDetail(facilityId)
      setFacilityId(facilityId);
      setFacilityData(response.data);
    } catch (error) {
      console.error("薬局情報の取得に失敗しました", error);
    }
  }

  // 編集画面への遷移
  const moveToEdit = () => {
    navigate(`/facility/edit`, { state: { facilityData } });
  };

  return (
    <FacilityDetailCommon
      facilityData={facilityData}
      moveToEdit={moveToEdit}
      editable={true}
      facilityType={facilityType}
    />
  );
};

export default FacilityDetail;
