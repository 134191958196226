import React from "react"
import {
  Stack,
  Grid,
  Typography,
} from '@mui/material';
import { styles } from '../style/commonStyles'

type HeadlineWidhLogoProps = {
  pageTitleName: string;
}
export const HeadlineWidhLogo: React.FC<HeadlineWidhLogoProps> = ({ pageTitleName }) => (
  <Stack spacing={5} sx={{mt: 10}}>
    <Grid container alignItems='center' justifyContent='center' direction="column">
      <Grid item xs={12}>
        <img src='/images/login/login_logo@2x.png' alt='Bizcare' style={{width: '179px', height: '72px'}}/>
      </Grid>
    </Grid>
    <Typography sx={styles.TypographyPageTitle} align='center'>{pageTitleName}</Typography>
  </Stack>
)
