import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Stack,
  TextField,
  AlertColor
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FacilityFormData } from '../schema/FacilitySchema';
import { User } from "interfaces/index";

import { useLocation, useNavigate } from 'react-router-dom';

import client from '../../lib/api/client';
import { getClinicDetail } from '../../lib/api/clinic';
import { getPharmacyDetail } from '../../lib/api/pharmacy';
import { Snackbar } from '../parts/Snackbar';
import { ButtonCaption, FacilityType } from "../../utils/Constants";

const buttonStyle = {
  minWidth: 120,
  width: 140,
  height: 35,
  boxShadow: "none",
  padding: "4px 16px",
  borderRadius: "3px",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "0.10000000149011612px",
  textAlign: "center",
  color: "#003642",
};

const cancelButtonStyle = {
  ...buttonStyle,
  backgroundColor: "#FFFFFF",
  border: "1px solid #87D1EA",
  "&:hover": {
    backgroundColor: "#F5F5F5",
    boxShadow: "none",
  },
};

const updateButtonStyle = {
  ...buttonStyle,
  backgroundColor: "#87D1EA",
  border: "1px solid #87D1EA",
  "&:hover": {
    backgroundColor: "#1aa3c9",
    boxShadow: "none",
  },
};

type Props = {
  facilityId: string
  facilityType: string | undefined,
  adminAuth?: User | null,
  schema: any,
}

const FacilityEditCommon: React.FC<Props> = (props) => {
  const {
    facilityId,
    facilityType,
    schema,
  } = props

  const location = useLocation();
  // location.stateに型アサーションを適用
  const state = location.state as { facilityData?: FacilityFormData };

  const [initialData, setInitialData] = useState<FacilityFormData>(new FacilityFormData());

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  useEffect(() => {
    // location.state.facilityData が存在するかを確認
    if (state && state.facilityData) {
      setInitialData(location.state.facilityData);
    } else {
      // location.state.facilityData が存在しない場合は、APIからデータをフェッチ
      const fetchInitialData = async () => {
        try {
          const response = facilityType === FacilityType.pharmacy ? await getPharmacyDetail(facilityId) : await getClinicDetail(facilityId);
          setInitialData(response.data);
        } catch (error) {
          console.error("施設情報の取得に失敗しました", error);
        }
      };
      if (facilityId !== "") {
        fetchInitialData();
      }
    }
  }, []); 

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: initialData ?? {}
  })

  const navigate = useNavigate();

  useEffect(() => {
    if (initialData) {
      reset(initialData); // フォームに初期データを設定
    }
  }, [initialData, reset]);
  
  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }

  // フォーム送信時の処理
  const onSubmit: SubmitHandler<FacilityFormData> = async (data) => {
    // 施設情報更新
    try {
      switch (facilityType) {
        case FacilityType.clinic:
          await client.put(`/clinic/${facilityId}`, data);
          setSnackbarProps('success', '医療機関情報が更新されました', true);
          return
        case FacilityType.pharmacy:
          await client.put(`/pharmacy/${facilityId}`, data);
          setSnackbarProps('success', '薬局情報が更新されました', true);
          return
        default:
          throw('未定義の施設タイプ');
      }
    } catch (error) {
      console.log(error)
      setSnackbarProps('error', '施設情報の更新に失敗しました', true);
    }
  }

  return (
    <>
      <Stack spacing={3}>
        {/* 施設名称 */}
        <TextField
          required
          label="施設名称"
          {...register('name')}
          error={!!errors.name}
          helperText={errors.name?.message} 
          InputLabelProps={{
            shrink: true, 
          }}
        />
        
        {/* 施設名称（略称） */}
        <TextField
          required
          label="施設名称（略称）"
          {...register('nameShort')}
          error={!!errors.nameShort}
          helperText={errors.nameShort?.message} 
          InputLabelProps={{
            shrink: true, 
          }}
        />
        
        {/* 施設名称（カナ） */}
        <TextField
          required
          label="施設名称（カナ）"
          {...register('kana')}
          error={!!errors.kana}
          helperText={errors.kana?.message}
          InputLabelProps={{
            shrink: true, 
          }}
        />

        {/* 診療科 */}
        { facilityType === FacilityType.clinic && <TextField
            label="診療科"
            {...register('department')}
            error={!!errors.department}
            helperText={errors.department?.message}
            InputLabelProps={{
              shrink: true, 
            }}
          />
        }

        {/* 郵便番号 */}
        <TextField
          required
          label="郵便番号"
          {...register('zip')}
          error={!!errors.zip}
          helperText={errors.zip?.message}
          InputLabelProps={{
            shrink: true, 
          }}
        />

        {/* 住所 */}
        <TextField
          required
          label="住所"
          {...register('address')}
          error={!!errors.address}
          helperText={errors.address?.message}
          InputLabelProps={{
            shrink: true, 
          }}
        />

        {/* 電話番号 */}
        <TextField
          required
          label="電話番号"
          {...register('tel')}
          error={!!errors.tel}
          helperText={errors.tel?.message}
          InputLabelProps={{
            shrink: true, 
          }}
        />

        {/* FAX番号 */}
        <TextField
          label="FAX番号"
          {...register('fax')}
          error={!!errors.fax}
          helperText={errors.fax?.message}
          InputLabelProps={{
            shrink: true, 
          }}
        />

        {/* 診療時間 */}
        <TextField
          label="診療時間"
          {...register('operatingHours')}
          error={!!errors.operatingHours}
          helperText={errors.operatingHours?.message}
          InputLabelProps={{
            shrink: true, 
          }}
        />

                {/* 施設案内 */}
                <TextField
          label="施設案内"
          {...register('information')}
          error={!!errors.information}
          helperText={errors.information?.message}
          multiline
          rows={5}
          InputLabelProps={{
            shrink: true, 
          }}
        />

        {/* 備考 */}
        <TextField
          label="備考"
          {...register('note')}
          error={!!errors.note}
          helperText={errors.note?.message}
          multiline
          rows={5}
          InputLabelProps={{
            shrink: true, 
          }}
        />

        {/* ChoiceReserveカテゴリID */}
        { props.adminAuth && 
          <TextField
            label="ChoiceReserveカテゴリID"
            {...register('categoryId')}
            error={!!errors.categoryId}
            helperText={errors.categoryId?.message}
            InputLabelProps={{
              shrink: true, 
            }}
          />
        }

        {/* ChoiceReserveダイレクトID */}
        {props.adminAuth &&
          <TextField
            label="ChoiceReserve連携URLID"
            {...register('directId')}
            error={!!errors.directId}
            helperText={errors.directId?.message}
            InputLabelProps={{
              shrink: true, 
            }}
          />
        }

        {/* ChoiceReserveダイレクトID */}
        {props.adminAuth &&
          <TextField
            label="FincodeショップID"
            {...register('fincodeShopId')}
            error={!!errors.fincodeShopId}
            helperText={errors.fincodeShopId?.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        }
      </Stack>

      <Grid container pt={3} spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={3} sx={{ marginRight: 2 }} >
        <Button
          sx={cancelButtonStyle}
          color="secondary"
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          {ButtonCaption.back}
        </Button>
        </Grid>
        <Grid item xs={3}>
        <Button
            sx={updateButtonStyle}
            color="primary"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
        >
          {ButtonCaption.update}
        </Button>
        </Grid>
      </Grid>

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </>
  );
}
export default FacilityEditCommon
       