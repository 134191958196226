import yup from "../../utils/yup.locale";

// フォームの型

// ログイン前のPW変更画面用
export class PasswordUpdateFormData {
  password: string = ''
  password_confirmation: string = ''
}

// ログイン後のPW変更画面用
export class PasswordEditFormData extends PasswordUpdateFormData {
  password_previous: string = ''
}

// バリデーションルール

// 共通パスワードバリデーションルール
const yupItemPasswordCommon = yup
  .string()
  .min(8)
  .max(128)
  .matches(/^(?=.*[a-z])(?=.*\d)[a-z\d]+$/, '半角英数字を両方含めてください')
  .required()

// ログイン前のPW変更画面用
export const PasswordUpdateSchema = yup.object().shape({
  password: yupItemPasswordCommon,
  password_confirmation: yupItemPasswordCommon
    .oneOf([yup.ref('password')], 'パスワードが同一ではありません')
});

// ログイン後のPW変更画面用
export const PasswordEditSchema = yup.object().shape({
  password: yupItemPasswordCommon
    .test(
      '説明',
      '現在のパスワードと新しいパスワードは一致しないようにしてください',
      (password, context) => {
        const password_previous = context.parent.password_previous
        console.log({password_previous, password, match: password_previous === password})
        if (password_previous === password) {
          return false
        }
        return true
      }
    ),
  password_confirmation: yupItemPasswordCommon
    .oneOf([yup.ref('password')], '新しいパスワードと同一ではありません'),
  password_previous: yupItemPasswordCommon,
});
