import { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Link,
  FormHelperText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { getPharmacyList } from '../lib/api/pharmacy'; 
import { useUserInfoRegistered } from '../hooks/useUserInfoRegistered'

import { PharmacyFormData } from '../components/schema/FacilitySchema';
import LayoutWithMenu from './LayoutWithMenu';
import { ButtonCaption, Messages } from "../utils/Constants";
import { icons } from "../icon";

const styles = {
  GridContainer: {
    borderBottom: 1,
    borderColor: 'lightGrey',
    fontSize: 14,
    textDecoration: 'none',
    color: '#000000',
    py: 1,
    backgroundColor: "#F5FAFD !important",
    paddingTop: 1,
    paddingBottom: 1,
  },
  GridItem: {
    alignContent: 'center',
    backgroundColor: "#F5FAFD !important",
  },
  LinkStyle: {
    cursor: 'pointer',
    color: '#003642', // リンクの色
    textDecoration: 'none', // アンダーラインを消す
    '&:hover': {
      textDecoration: 'underline', // ホバー時にアンダーラインを表示
      color: '#1aa3c9'
    },
    fontWeight: 400,
  },
  DepartmentLabel: {
    fontSize: 12,
    marginTop: 3,
    marginLeft: 30,
  },
}

const PharmacyList = () => {

  const [pharmacies, setPharmacies] = useState<Array<PharmacyFormData>>([]);

  // ユーザー情報が登録されているか
  const {isUserInfoRegistered, userErrorMessage, userNotificationMessage} = useUserInfoRegistered()
  
  const navigation = useNavigate();

  // 初期表示処理
  useEffect(() => {
    initProc()
  },[])

  const initProc = async () => {
    await displayData();
    await isUserInfoRegistered();
  }

  // 初期表示データ取得
  const displayData = async () => {
    try {
      const response = await getPharmacyList();
      setPharmacies(response.data);
    } catch (error) {
      console.error("薬局一覧の取得に失敗しました", error);
    }
  }

  // 詳細画面遷移
  const moveToDetail = (data: PharmacyFormData) => {
    navigation(`detail/${data.id}`);
  }

  // 予約紐付け画面遷移
  const moveToRelation = (data: PharmacyFormData) => {
    navigation(
      `reservation/`,
      {state: {id: data.id, name: data.nameShort || data.name, directId: data.directId}});
  }

  return (
    <LayoutWithMenu pageTitle={'調剤予約（薬局）'}>
      <FormHelperText error={true} sx={{whiteSpace: 'pre-line'}}>{userErrorMessage}</FormHelperText>
      <FormHelperText sx={{whiteSpace: 'pre-line'}}>{userNotificationMessage}</FormHelperText>
      {pharmacies.map((data, index) => {
        return (
          <Grid container key={index} sx={styles.GridContainer}>
            <Grid container item xs={1} sx={[styles.GridItem, {paddingLeft: '5px'}]}>
            <icons.MedicinesIcon style={{ width: "20px", height: "20px", marginRight: "0px" }} />
            </Grid>
            <Grid container item xs={7} sx={[styles.GridItem, {paddingLeft: '1px'}]}>
              <Link sx={styles.LinkStyle} onClick={ () => moveToDetail(data) }>
                {data.nameShort || data.name} 
              </Link>
            </Grid>
            <Grid container item xs={3} sx={styles.GridItem}>
              <Button
                sx={{
                  backgroundColor: '#21bddb',
                  boxShadow: 'none',
                  fontSize: { xs: '0.65rem', sm: '0.775rem' },
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: '#1aa3c9',
                    boxShadow: 'none',
                  },
                }}
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => moveToRelation(data)}
                disabled={userErrorMessage !== '' || !data.directId}
              >
                {ButtonCaption.reservation}
                </Button>
            </Grid>
            { !data.directId &&
                <FormHelperText
                  error={true}
                  sx={{ whiteSpace: 'pre-line', fontSize: '0.7em',paddingLeft: '10px' }}
                >
                  {Messages.cannotReservation}
                </FormHelperText>
            }
          </Grid>
        )
      })}

    </LayoutWithMenu>
  )
}
export default PharmacyList