import { encryptedParam } from '../lib/api/reservation';
import { getUserDetail } from '../lib/api/user';

// 遷移パラメータ
export type MoveToChoiceReserveParam = {
  directId: string,
  userId: number | undefined,
  facilityTypeCode: number,
  relationIds?: number[],
  onlyMedNoRx?: boolean,
  onlyMedWithRx?: boolean,
  isNewWindow?: boolean,
  prescriptionRecordId?: number
}

// ChoiceReserve予約画面への遷移
export const moveToChoiceReserve = async (param: MoveToChoiceReserveParam) => {

  // ユーザーIDなしは何もしない
  if (param.userId === undefined)  return;

  try {
    // ログインユーザー情報の取得
    const user = await getUserDetail(param.userId.toString())
    const birthToDate = user.data.birth ? new Date(user.data.birth) : '';
    const bYear = birthToDate ? birthToDate.getFullYear() : '';
    const bMonth = birthToDate ? birthToDate.getMonth() + 1 : '';
    const bDate = birthToDate ? birthToDate.getDate() : '';
    
    // unixTimeの取得
    const date = new Date();
    const unixTime = Math.floor(date.getTime() / 1000);

    // 連携パラメータの暗号化
    const params = {
      ut: unixTime,
      extid: createDirectIdParam(
        param.userId,
        param.facilityTypeCode,
        param.onlyMedNoRx || false,
        param.onlyMedWithRx || false,
        param.relationIds || [],
        param.prescriptionRecordId || null),
      name: user.data.name,
      kana: user.data.kana,
      mail: user.data.email,
      tel: user.data.phone,
      zip: user.data.postalCode,
      pref: user.data.prefecture,
      addr1: user.data.address1 + user.data.address2,
      addr2: user.data.address3,
      sex: user.data.sex,
      birthy: bYear,
      birthm: bMonth,
      birthd: bDate
    }
    const result = await encryptedParam(params);
    const data = result.data;
    
    // 返却値を使用してChoiceReserveへ連携
    const base_uri = process.env.REACT_APP_CHOICE_RESERVE_BASE_URL || "https://demo1-bizcare.resv.jp";
    const uri = `${base_uri}/connect/index.php`
    const form = document.createElement('form')
    form.setAttribute('method', 'post');
    form.setAttribute('action', uri);
    document.body.appendChild(form);

    // Formデータをセット
    form.addEventListener('formdata', (e) => {
      var fd = e.formData;
      Object.keys(data).forEach((key) => {
        fd.set(key, data[key]);
      })
      if (param.directId !== "") {
        fd.set('func', 'direct_calendar');
        fd.set('direct_id', param.directId || '');
      }
    });

    // ChoiceReserve予約画面遷移
    if (param.isNewWindow) {
      const windowName = 'new_window';
      window.open('', windowName);
      form.target = windowName;
    }
    form.submit();
  } catch (error) {
    alert("予期せぬエラーが発生しました。管理者へお問い合わせください");
    console.log(error)
  }
}

// 連携ID組み立て
const createDirectIdParam = (
  userId: number,
  type: number,
  onlyMedNoRx: boolean,
  onlyMedWithRx: boolean,
  relationIds: number[],
  prescriptionId: number | null
) => {
  return `{"user": ${userId}, "type": ${type}, "relations": [${relationIds}], "medNoRx": ${Number(onlyMedNoRx)}, "medWithRx": ${Number(onlyMedWithRx)}, "pres": ${prescriptionId}}`
}