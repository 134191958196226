import { useEffect, useState, useRef, createRef, RefObject } from 'react';
import { Snackbar } from './parts/Snackbar';
import LayoutWithMenu from './LayoutWithMenu';
import ImageModal from './parts/ImageModal';

import {
  AlertColor,
  Grid,
  Button,
} from "@mui/material";

import {
  getReservationListByUser,
} from '../lib/api/reservation';

import { Reservation } from '../interfaces/index';
import { useAuthUserContext } from '../contexts/AuthUserContext'
import { styles as commonStyles } from './style/commonStyles';
import { formattedDatetime } from '../utils/helper';

const ReceiptDetailList = () => {
  const authUser = useAuthUserContext().authUser
  const [reservations, setReservations] = useState<Reservation[]>([]);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage] = useState<string>('');
  const [severity] = useState<AlertColor>('success');

  // 診療明細表示領域制御用
  const receiptRef = useRef<RefObject<HTMLDivElement>[]>([]);
  const [displayIndex, setDisplayIndex] = useState<number>(-1);

  useEffect(() => {
    getReservationListByUserData();
  },[authUser])

  // 予約情報取得
  const getReservationListByUserData = async () => {
    if (!authUser) {
      return;
    }

    try {
      const response = await getReservationListByUser(authUser.id.toString())
      setReservations(response.data);
      createRefObject(response.data);
    } catch (error) {
      console.error('予約一覧の取得に失敗しました', error);
    }
  };

  //  診療明細表示部への参照を作る
  const createRefObject = (reservationList: Reservation[]) => {
    reservationList.map((_, i) => {
      receiptRef.current[i] = createRef<HTMLDivElement>();
    })
  }

  // style定義
  const styles = {
    deleteIcon: {
      position: 'absolute',
      top: 10,
      right: 0,
    },
    previewImageGrid: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      position: 'relative',
    },
    ...commonStyles,
    GridContainer: {
      borderBottom: 1,
      borderColor: 'lightGrey',
      py: 1,
      fontSize: 16,
      textDecoration: 'none',
      color: '#000000',
      paddingTop: 5,
      paddingBottom: 5
    },
    GridItem: {
      alignContent: 'center',
    }
  }

  // Modal表示管理
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalImgSrc, setModalImgSrc] = useState<string>('')

  // Modal表示内容セット
  const setModalContent = (url: string) => {
    setModalImgSrc(url)
    setModalOpen(true)
  }

  // 診療明細表示領域開閉
  const openCloseReceiptArea = (index: number) => {
    if (displayIndex === index) return setDisplayIndex(-1)
    setDisplayIndex(index)
  }

  return (
    <LayoutWithMenu
      pageTitle={`診療明細一覧 (${authUser?.name} さま)`}
    >
       {reservations.map((reservation, index) => (
        reservation.receiptFiles.length > 0 && // 診療明細なしの医療機関予約は表示されない
        <Grid container key={index} sx={styles.GridContainer}>
          <Grid container item xs={7} sx={styles.GridItem}>
            <div>
              <p>{reservation.reservationTime ? formattedDatetime(reservation.reservationTime) : ""}</p>
              <p>{reservation.facility.name}</p>
            </div>
          </Grid>

          {/* クリックしたら診療明細情報を表示する */}
          <Grid container item xs={2} sx={styles.GridItem}>
            <Button
              onClick={() => openCloseReceiptArea(index)}>
              {`${reservation.receiptFiles?.length}枚`}
            </Button>
          </Grid>
          
          {/* 診療明細表示部 */}
          <div
            ref={receiptRef.current[index]}
            style={ displayIndex === index ? {
              height: receiptRef.current[index].current?.scrollHeight || 0
            } : {height: "0px", overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              {reservation.receiptFiles.map((image, index) => (
                <Grid item xs={6} md={4} sx={styles.GridItem} key={index}>
                  <div
                    style={{
                      width: '80%',
                      height: '80%',
                      objectFit: 'contain',
                      aspectRatio: '1 / 1'
                    }}
                    onClick={!image.url.endsWith('.pdf') ? () => setModalContent(image.url) : undefined}
                  >
                    {image.url.endsWith('.pdf') ? (
                      <>
                        <object
                          data={image.url}
                          type="application/pdf"
                          style={{ width: '100%', height: '100%' }}
                          aria-label="PDFを開く"
                        />
                        <a href={image.url} target="_blank" rel="noopener noreferrer" style={{color: 'blue'}}>
                          PDFを開く
                        </a>
                    </>
                    ) : (
                      <img
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                        src={image.url}
                        alt="診療明細"
                      />
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Grid>
      ))}

      <ImageModal
        open={modalOpen}
        setOpen={setModalOpen}
        imgSrc={modalImgSrc}
      />

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
}

export default ReceiptDetailList;
