import LayoutWithMenu from "./LayoutWithMenu";
import {Box, Button, Grid, FormHelperText, FormGroup, FormControlLabel, Checkbox, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {ButtonCaption, Messages, FacilityTypeCode} from "../utils/Constants";
import {moveToChoiceReserve, MoveToChoiceReserveParam} from "../utils/choiceReserve";
import {getNoRelateClinicReservation} from '../lib/api/reservation';
import {Reservation} from '../interfaces/index';
import {formattedDatetime} from '../utils/helper';

import {useNavigate, useLocation} from "react-router-dom";
import {useAuthUserContext} from "../contexts/AuthUserContext";

import CustomDialog from "./parts/CustomDialog";

type LocationParam = {
  pharmacyId: string,
  name: string,
  directId: string,
}

const PharmacyReservation = () => {
  const location = useLocation();
  const [param] = useState<LocationParam>(location.state)
  
  const navigation = useNavigate()
  const authUser = useAuthUserContext().authUser

  const [clinicReservations, setClinicReservations] = useState<Array<Reservation>>([]);
  const [relationReservations, setRelationReservations] = useState<Array<number>>([]);
  const [onlyMedNoRx, setOnlyMedNoRx] = useState<boolean>(false);
  const [onlyMedWithRx, setOnlyMedWithRx] = useState<boolean>(false);
  
  // dialog用
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<string>('');
  const [isHiddenCancel, setIsHiddenCancel] = useState<boolean>(false);
  const [handleOnConfirm, setHandleOnConfirm] = useState<() => void>(() => {});
  // エラー表示
  const [errorMessage, setErrorMessage] = useState<string>('');


  useEffect(() => {
    // パラメータチェック:必要なパラメータが空の場合、１つ前の画面から処理をやり直す
    if (!param) {
      setDialogContent("処理が正しく行われませんでした。薬局の選択からやり直してください")
      setIsHiddenCancel(true);
      setHandleOnConfirm(() => moveToPharmacyList)
      setIsDialogOpen(true);
      return;
    }
    // direct_id(choiceReserve連携用ID)未設定、またはユーザー名が登録されていない場合、エラーとする
    if (!param?.directId) setErrorMessage(Messages.cannotReservation);
    if (!authUser?.name) setErrorMessage(Messages.userUnregisteredShort)

    // 紐付け対象予約リスト取得
    targetClinicReservations()
  }, []);

  useEffect(() => {
    if (relationReservations.length === 0  && !onlyMedNoRx && !onlyMedWithRx) {
      setErrorMessage("1件以上選択してください");
    } else {
      setErrorMessage('');
    }
  }, [relationReservations, onlyMedNoRx, onlyMedWithRx])

  // 薬局一覧画面へ戻る
  const moveToPharmacyList = () => {
    navigation("/pharmacy");
  }

  // 紐付け対象の医療機関予約取得
  const targetClinicReservations = async () => {
    const result = await getNoRelateClinicReservation(authUser?.id.toString() || '')
    const resultList = result.data as Array<Reservation>
    setClinicReservations(
      resultList.map((data) => {
        return {
          id: data.id,
          userId: data.userId,
          facilityId: data.facilityId,
          reservationTime: data.reservationTime,
          status: data.status,
          receiptFiles: [],
          isCanceled: data.isCanceled,
          facility: data.facility,
          zoomUrl: data.zoomUrl,
        }
      })
    )
  }

  // 外部サイト遷移確認
  const openMoveConfirmDialog = () => {
    if (onlyMedWithRx) moveToRegisterPrescription();

    if (!onlyMedWithRx) {
      // 服薬指導のみ＋手元処方せんなしの場合、外部予約サイトへ遷移する
      const message = Messages.pharmacyReservationNotice;
      setDialogContent(message)
      setIsHiddenCancel(false);
      setHandleOnConfirm(() => moveToChoiceReserveSite)
      setIsDialogOpen(true);
    }
  }

  // 処方せん登録画面遷移
  const moveToRegisterPrescription = () => {
    navigation(
      `/prescription/register`,
      {state: {
        directId: param?.directId,
        relationReservations: relationReservations,
        onlyMedNoRx: onlyMedNoRx,
        onlyMedWithRx: onlyMedWithRx,
        pharmacyName: param?.name
      }});
  }

  // choiceRESERVE予約画面への遷移
  const moveToChoiceReserveSite = () => {
    const moveToParam = {
      directId: param?.directId,
      userId: authUser?.id,
      facilityTypeCode: FacilityTypeCode.pharmacy,
      relationIds: relationReservations,
      onlyMedNoRx: onlyMedNoRx,
      onlyMedWithRx: onlyMedWithRx
    } as MoveToChoiceReserveParam;
    moveToChoiceReserve(moveToParam);
  }

  // 予約チェック状態変更
  const handleClinicReservationCheck = (isChecked: boolean, reservationId: number) => {
    // checkON時に関連予約IDの配列に追加、OFF時に関連予約ID配列より削除
    if(isChecked) {
      setRelationReservations([...relationReservations, reservationId])
    } else {
      setRelationReservations(
        relationReservations.filter((item) => item !== reservationId )
      )
    }
  }

  return (
    <LayoutWithMenu pageTitle={`${param?.name || ''} 服薬指導予約`}>

      <Box sx={{pt: '2em', px: '1em'}} >
        <Typography>{'服薬指導・調剤を依頼する診療（処方せん）を選択してください。'}</Typography>
        <Box sx={{px: '1em', py: '2em'}} >
          <FormGroup>
            <FormControlLabel
              sx={{pt: 1}}
              label={'オンライン診療ではない服薬指導の予約（処方せんは手元にある）'}
              control={<Checkbox onChange={(e) => setOnlyMedWithRx(e.target.checked)} />} />
            
            <FormControlLabel
                sx={{py: 1}}
                label={'オンライン診療ではない服薬指導の予約（処方せんは医療機関にある）'}
                control={<Checkbox onChange={(e) => setOnlyMedNoRx(e.target.checked)} />} />

            { clinicReservations.map((data, idx) => {
              return (
                <FormControlLabel
                  sx={{py: 1}}
                  key={idx}
                  label={`${data.facility.name} (${formattedDatetime(data.reservationTime)})`}
                  control={<Checkbox onChange={(e) => handleClinicReservationCheck(e.target.checked, data.id)} />} />
              )
            }) }
          </FormGroup>
        </Box>
      </Box>

      {/* ボタン */}
      <Grid container pt={3} spacing={2}>
        <Grid item xs={3}>
            <Button
            sx={{
              color: 'black',
              borderColor: 'black', // 枠線の色
              '&:hover': {
                backgroundColor: 'transparent', // ホバー時の背景色を透明に
                borderColor: 'black', // ホバー時も枠線を黒に保つ
              },
              width: '100%',
            }}
            
                color="secondary"
                variant="outlined"
                onClick={() => navigation(-1)}
              >
                {ButtonCaption.back}
              </Button>
          </Grid>
        <Grid item xs={9}>
          <Button
            sx={{
              backgroundColor: '#21bddb',
              boxShadow: 'none',
              fontSize: { xs: '0.75rem', sm: '0.875rem' },
              fontWeight: 600,
              '&:hover': {
                backgroundColor: '#1aa3c9',
                boxShadow: 'none',
              },
              width: '100%',
            }}
            color="primary"
            variant="contained"
            onClick={() => openMoveConfirmDialog()}
            disabled={errorMessage !== ''}
          >
            { onlyMedWithRx ? '次へ進む' : ButtonCaption.reservation }
          </Button>
          { errorMessage &&
            <FormHelperText error={true} sx={{ whiteSpace: 'pre-line' }}>{errorMessage}</FormHelperText> }
        </Grid>
      </Grid>

      <CustomDialog
        isOpen={isDialogOpen}
        content={dialogContent}
        isHiddenCancel={isHiddenCancel}
        onConfirm={handleOnConfirm}
        onCancel={() => setIsDialogOpen(false)}
      />
    </LayoutWithMenu>
  )
}
export default PharmacyReservation;
