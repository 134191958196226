import { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  AlertColor,
  FormHelperText,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import imageCompression from "browser-image-compression";
import { useNavigate } from 'react-router-dom';

import { styles } from './style/commonStyles'
import LayoutWithMenu from './LayoutWithMenu';
import FileUploader from './parts/FileUploader';
import { Snackbar } from './parts/Snackbar';
import { ButtonCaption } from "../utils/Constants";
import { getUserDetail, registerImages, deleteNotebookImage } from '../lib/api/user';
import { useAuthUserContext } from '../contexts/AuthUserContext'
import {randomFileName} from "../utils/helper";

const MedicineNotebook = () => {
  const authUser = useAuthUserContext().authUser
  const navigation = useNavigate();

  // 薬手帳画像の状態を管理するためのuseStateフック
  const [medicineNotebook1, setMedicineNotebook1] = useState<File[]>([]);
  const [notebook1URL, setNotebook1URL] = useState<string[]>([]);
  const [medicineNotebook2, setMedicineNotebook2] = useState<File[]>([]);
  const [notebook2URL, setNotebook2URL] = useState<string[]>([]);
  const [medicineNotebook3, setMedicineNotebook3] = useState<File[]>([]);
  const [notebook3URL, setNotebook3URL] = useState<string[]>([]);
  const [medicineNotebook4, setMedicineNotebook4] = useState<File[]>([]);
  const [notebook4URL, setNotebook4URL] = useState<string[]>([]);
  const [medicineNotebook5, setMedicineNotebook5] = useState<File[]>([]);
  const [notebook5URL, setNotebook5URL] = useState<string[]>([]);
  const [medicineNotebook6, setMedicineNotebook6] = useState<File[]>([]);
  const [notebook6URL, setNotebook6URL] = useState<string[]>([]);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  useEffect(() => {
    getMedicineNotebookData()
  },[])

  const getMedicineNotebookData = async () => {
    try {
      const response = await getUserDetail(authUser?.id.toString() || '')
      // 薬手帳画像URLの状態を設定
      setNotebook1URL([response.data.medicineNotebook1?.url])
      setNotebook2URL([response.data.medicineNotebook2?.url])
      setNotebook3URL([response.data.medicineNotebook3?.url])
      setNotebook4URL([response.data.medicineNotebook4?.url])
      setNotebook5URL([response.data.medicineNotebook5?.url])
      setNotebook6URL([response.data.medicineNotebook6?.url])
      // プレビュー用画像のクリア
      setMedicineNotebook1([])
      setMedicineNotebook2([])
      setMedicineNotebook3([])
      setMedicineNotebook4([])
      setMedicineNotebook5([])
      setMedicineNotebook6([])
    } catch (error) {
      console.log(error)
    }
  }

  // 登録ボタン制御
  const isRegisterDisabled =
    medicineNotebook1.length === 0 && medicineNotebook2.length === 0 &&
    medicineNotebook3.length === 0 && medicineNotebook4.length === 0 &&
    medicineNotebook5.length === 0 && medicineNotebook6.length === 0

  // 登録処理
  const register = async () => {
    const compressOptions = { maxSizeMB: 3 } // 3MB以下に圧縮

    // ファイルを圧縮する関数
    const processFile = async (file: File | null) => {
      if (!file) return null;
      // 画像ファイルの場合は圧縮
      if (file.type.startsWith('image/')) {
        return await imageCompression(file, compressOptions);
      }
      return file;
    };

    const compressedNotebook1 = await processFile(medicineNotebook1[0]);
    const compressedNotebook2 = await processFile(medicineNotebook2[0]);
    const compressedNotebook3 = await processFile(medicineNotebook3[0]);
    const compressedNotebook4 = await processFile(medicineNotebook4[0]);
    const compressedNotebook5 = await processFile(medicineNotebook5[0]);
    const compressedNotebook6 = await processFile(medicineNotebook6[0]);

    const formData = new FormData();
    if (compressedNotebook1)
      formData.append('user[medicine_notebook1]', compressedNotebook1, randomFileName(medicineNotebook1[0].name));
    if (compressedNotebook2)
      formData.append('user[medicine_notebook2]', compressedNotebook2, randomFileName(medicineNotebook2[0].name));
    if (compressedNotebook3)
      formData.append('user[medicine_notebook3]', compressedNotebook3, randomFileName(medicineNotebook3[0].name));
    if (compressedNotebook4)
      formData.append('user[medicine_notebook4]', compressedNotebook4, randomFileName(medicineNotebook4[0].name));
    if (compressedNotebook5)
      formData.append('user[medicine_notebook5]', compressedNotebook5, randomFileName(medicineNotebook5[0].name));
    if (compressedNotebook6)
      formData.append('user[medicine_notebook6]', compressedNotebook6, randomFileName(medicineNotebook6[0].name));

    // APIへPOST
    try {
      await registerImages(authUser?.id.toString() || '', formData);
      await getMedicineNotebookData();
      setSnackbarProps('success', 'お薬手帳画像を登録しました', true);
    } catch (error) {
      setSnackbarProps('error', 'お薬手帳画像の登録に失敗しました', true);
    }
  }

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }

  const handleDeleteFile = async (fileName: string, index: number) => {
    try {
      await deleteNotebookImage(authUser?.id.toString() || '', fileName);

      const setNotebookURLFunctions = [
        setNotebook1URL,
        setNotebook2URL,
        setNotebook3URL,
        setNotebook4URL,
        setNotebook5URL,
        setNotebook6URL,
      ];
      if (index >= 1 && index <= setNotebookURLFunctions.length) {
        setNotebookURLFunctions[index - 1]([]);
      }

      setSnackbarProps('success', 'お薬手帳画像が削除されました', true);
    } catch (error) {
      console.error(error);
      setSnackbarProps('error', 'お薬手帳画像の削除に失敗しました', true);
    }
  }

  return (
    <LayoutWithMenu>
      <Typography
        sx={{ ...styles.TypographyPageTitle, mt: 10}}
        align="center"
      >
        お薬手帳登録
      </Typography>
      {notebook1URL[0] === null &&
        notebook2URL[0] === null &&
        notebook3URL[0] === null &&
        notebook4URL[0] === null &&
        notebook5URL[0] === null &&
        notebook6URL[0] === null && (
          <FormHelperText
            sx={{ textAlign: "center", mt: 2, mb: 4 }}
            error={true}
          >
            お薬手帳画像を登録してください
          </FormHelperText>
        )}
      <Grid container spacing={2}>
        {/* 薬手帳画像アップロードUI */}
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Stack
              key={index}
              sx={{ ...styles.gridContainer, borderBottom: "none" }}
            >
              <Box>
                <FileUploader
                  images={eval(`medicineNotebook${index}`)}
                  setImages={eval(`setMedicineNotebook${index}`)}
                  isSingleSelect={true}
                  imagesURL={eval(`notebook${index}URL`)}
                  setImagesURL={eval(`setNotebook${index}URL`)}
                  onDeleteFile2={handleDeleteFile}
                  accept="image/*,.png,.jpg,.jpeg,.gif"
                  buttonText={"ファイルを選択する"}
                />
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>

      <Box display="flex" justifyContent="center" alignItems="baseline" gap="1.5rem">
        {/* 戻るボタン */}
        <Button
          sx={[styles.buttonOutlined]}
          color="primary"
          variant="outlined"
          size="medium"
          onClick={() => navigation(-1)}
        >
          {ButtonCaption.back}
        </Button>
        <Stack spacing={2} alignItems="center" sx={{width: "100%"}}>
          <Button
            sx={{
              backgroundColor: "#87D1EA",
              boxShadow: "none",
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#5cb9d3",
                boxShadow: "none",
              },
              width: "100%",
            }}
            disabled={isRegisterDisabled}
            color="primary"
            variant="contained"
            size="medium"
            onClick={register}
          >
            {ButtonCaption.register}
          </Button>
          {isRegisterDisabled && (
            <FormHelperText error={true}>
              {"変更する場合は画像を選択してください"}
            </FormHelperText>
          )}
        </Stack>
      </Box>

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
}
export default MedicineNotebook
