import React, { useEffect, useState } from "react";

import LayoutWithMenu from "../LayoutWithMenu";
import UserImageListCommon from "./UserImageListCommon";
import { ReservedUser } from "../../interfaces";
import { getReservedUserList } from "../../lib/api/user";
import { useAuthFacilityStaffContext } from '../../contexts/AuthFacilityStaffContext'

const styles = {
  GridContainer: {
    borderBottom: 1,
    borderColor: 'lightGrey',
    py: 1,
    fontSize: 16,
    textDecoration: 'none',
    color: '#000000',
    paddingTop: 5,
    paddingBottom: 5
  },
  GridItem: {
    alignContent: 'center',
  }
}

const MedicinebookList = () => {
  const [reservedUsers, setReservedUsers] = useState<ReservedUser[]>([]);
  const authFacilityStaff = useAuthFacilityStaffContext().authFacilityStaff;

  useEffect(() => {
    fetchReservedUserList();
  }, [authFacilityStaff]);

  // 初期表示
  const fetchReservedUserList = async () => {
    if (!authFacilityStaff) {
      return;
    }

    try {
      const response = await getReservedUserList(authFacilityStaff.facilityId.toString());
      setReservedUsers(response.data);
    } catch (error) {
      console.error('ユーザー一覧の取得に失敗しました', error);
    }
  };

  return (
    <LayoutWithMenu
      pageTitle={'お薬手帳'}
    >
      <UserImageListCommon
        reservedUsers={reservedUsers}
        imagePropName={"medicineNotebookImages"}
      />
    </LayoutWithMenu>
  )
}
export default MedicinebookList;
