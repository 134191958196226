import yup from "../../utils/yup.locale";

// フォームの型
export class LogInFormData {
  email: string = ''
  password: string = ''
  otp?: string = ''
}

// バリデーションルール
export const LogInSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  password: yup
    .string()
    .min(8)
    .max(128)
    .matches(/^(?=.*[a-z])(?=.*\d)[a-z\d]+$/, '半角英数字を両方含めてください')
    .required(),
  otp: yup
    .string()
    .optional()
    .min(1)
});
